import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GetTaxiService } from '../../services/get-taxi.service';
import { IHotelInfo } from '../../models/hotel-info.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PaymentApiService } from '../../services/api/payment-api.service';
import { SaveTaxiService } from '../../services/save-taxi.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ErrorOrderPopupComponent } from '../../shared/components/error-order-popup/error-order-popup.component';
import { AddressValidator } from '../../../validators/address.validator';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { OrderType } from 'src/app/enums/order-type.enum';
import { IDestination } from 'src/app/shared/models/destination.interface';
import { PriceService } from 'src/app/services/price.service';
import { HotelService, HotelSystemIntegration, NoDestinationSettings, PaymentOption } from 'src/app/services/hotel.service';
import { LogService } from 'src/app/services/log.service';
import { IPaymentInfo } from 'src/app/models/payment/paymentInfo.interface';
import { ISaveOtherDestinationTerminalRequest } from 'src/app/models/ride/save-otherdestination-terminal.interface';
import { RideType } from 'src/app/models/enums';
import { GetHotelSystem } from 'src/app/services/get-hotel.service';
import { ISaveOtherDestinationRequest } from 'src/app/models/ride/save-otherdestination.interface';
import { OrderedPopupComponent } from 'src/app/shared/components/ordered-popup/ordered-popup.component';
import { IDirectTaxiRequest } from 'src/app/models/ride/directtaxi.interface';
import { GeolocationService } from 'src/app/services/geo-location.service';
import { PaidPopupEmailComponent } from 'src/app/shared/components/paid-popup-email/paid-popup-email.component';
import { InitPopupQRRestaurantComponent } from 'src/app/shared/components/init-popup-qr-restaurant/init-popup-qr-restaurant.component';

@Component({
    selector: 'app-qr-restaurant',
    templateUrl: './qr-restaurant.component.html',
    styleUrls: ['./qr-restaurant.component.scss']
})

export class QRRestaurantComponent implements OnInit {
    private accessToken?: string;
    private redirectUrl?: string;
    isSubmit = false;
    isAsap = false;
    hotelInfo?: IHotelInfo;
    loading!: HTMLIonLoadingElement;
    isFirstPageClick: boolean = true;
    terminal: any;
    hotelId: string = '';
    days = 25;
    maxDate = new Date(Date.now() + this.days * 24 * 60 * 60 * 1000);
    selectedDate = new Date();
    btnLoader: boolean = false;
    lockedRoutes?: { id: string, name: string, address: string }[];
    currentRouteAddress: string = "";
    showApplyToBill: boolean = false;
    noDestinationSelected: boolean = false;
    payInTaxiSelected: boolean = false;
    options: any;
    airports?: { id: string, name: string, address: string }[];
    showPaid: boolean = false;
    whatToShow: number = 0;
    mobileLocation: IDestination = { address: '', name: '' };
    specialLocations: IDestination[] = [];
    initPopupQRRestaurantComponent: MatDialogRef<InitPopupQRRestaurantComponent> | null = null;

    taxiNames: string[] = [];
    selectedTaxiCompany: string | undefined;
    isLogoAvailable: { [key: string]: boolean } = {};

    constructor(private translate: TranslateService, public loadingController: LoadingController, private saveTaxiService: SaveTaxiService, private log: LogService, private route: ActivatedRoute, router: Router, private getTaxiService: GetTaxiService, public dialog: MatDialog, private paymentApiService: PaymentApiService, private alertController: AlertController, private taxiConfigurationService: TaxiConfigurationService, public priceService: PriceService, private getHotelSystem: GetHotelSystem, private hotelService: HotelService, private geolocationService: GeolocationService, private cdr: ChangeDetectorRef) {
        let lang = localStorage.getItem('lang');
        translate.setDefaultLang(lang ? lang : 'en');

        this.route.queryParams.subscribe(async (params) => {
            if (params.guid) {
                this.showPaid = true;
                // Remove the guid-part from the query string (so the taxi won't be reordered if the page is refreshed)
                router.navigate(['/qr-restaurant'], { queryParams: { hotelId: params.hotelId }, replaceUrl: true });

                window.onload = async () => {
                    let resultTr = this.translate.instant('orderingTaxiLoader')
                    await this.presentLoading(this.translate.instant(resultTr));
                };

                this.paymentApiService.getCheckout(params.guid).subscribe(async (data) => {
                    // If data is null we couldn't find any payment info on the backend
                    if (data === null) {
                        log.error('Payment info not found on the server - skip booking', { checkoutId: params.guid });
                        await this.hideLoading();
                        return;
                    }

                    const saveOtherDestinationTerminalRequest: ISaveOtherDestinationTerminalRequest = {
                        ...data,
                        orderType: OrderType.Mobile,
                        paymentOption: PaymentOption.PayByTerminal,
                        largeTaxi: false,
                        numberOfGuests: 1,
                        numberOfCars: 1,
                        stationCar: false,
                        attributesToTaxi: 0,
                    }

                    this.saveTaxiService.saveOtherDestinationTerminal(saveOtherDestinationTerminalRequest).subscribe(async (data: string) => {
                        await this.hideLoading();
                        const dialogRef = this.dialog.open(PaidPopupEmailComponent, {
                            data: {
                                rideId: data
                            }
                        });
                        dialogRef.afterClosed().subscribe(result => {
                            this.resetInterface();
                            if (this.redirectUrl !== undefined) {
                                window.location.href = this.redirectUrl;
                            }
                        });
                    }, async (error) => {
                        await this.hideLoading();
                        this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
                    });
                });
            }

            if (!this.showPaid) {
                this.showInit();
            }

            if (params.hotelId) {
                this.hotelId = params.hotelId;
                this.accessToken = params['accessToken'];

                this.redirectUrl = window.location.origin + window.location.pathname + '?hotelId=' + params.hotelId;
                if (this.accessToken)
                    this.redirectUrl += '&accessToken=' + this.accessToken;

                this.getTaxiService.getAirportsForHotel(this.hotelId).subscribe(airports => {
                    this.airports = airports;
                });
            }

            if (this.hotelService.hotelInfoValue) {
                this.hotelInfo = this.hotelService.hotelInfoValue;
                this.updateHotelInfo();
            }
            else {
                this.hotelService.hotelInfo.subscribe(hotelInfo => {
                    this.hotelInfo = hotelInfo;
                    this.updateHotelInfo();
                });
            }
        });
    }

    taxiOrderForm: FormGroup = new FormGroup({
        fromLocation: new FormControl('', [AddressValidator.detailedAddressValidator]),
        destination: new FormControl({ value: '', disabled: false }, [AddressValidator.detailedAddressValidator]),
        pickupDateTime: new FormControl(new Date(), [Validators.required]),
        fullName: new FormControl(''),
        isAsap: new FormControl(false),
        phoneNumber: new FormControl('', [Validators.required, Validators.min(4)]),
        roomNo: new FormControl(),
        paymentOption: new FormControl(PaymentOption.PayByTerminal, [Validators.required]),
    });

    ngOnInit(): void {
        this.priceService.loadingTaxiPrice.subscribe(value => this.btnLoader = value);

        this.taxiConfigurationService.taxiNames.subscribe((data) => {
            this.taxiNames = data;
            this.taxiNames.forEach(taxiName => {
                this.isLogoAvailable[taxiName] = true;
            });
        });

        this.taxiConfigurationService.taxiName.subscribe((data) => {
            if (!this.selectedTaxiCompany) {
                this.selectedTaxiCompany = this.taxiConfigurationService.taxiNameValue;
                this.priceService.setChosenTaxiCompany(this.selectedTaxiCompany, false);
                this.cdr.detectChanges();
            }
        });

        this.taxiOrderForm.get('fromLocation')?.valueChanges.subscribe(async (value: IDestination) => {
            this.setFromAddress(value)
        });

        this.taxiOrderForm.get('destination')?.valueChanges.subscribe(async (value: IDestination) => {
            this.setToAddress(value);
        });

        this.hotelService.paymentOptions.subscribe(options => {
            this.options = options;
        });

        this.taxiOrderForm.get('paymentOption')?.valueChanges.subscribe(async (value: PaymentOption) => {
            this.setPayButtonText(value);
        });
    }

    updateHotelInfo() {
        if (this.hotelInfo!.country == 'Finland') {
            this.hotelInfo!.selfServicePayInTaxiAllowed = false;
            this.paymentOption?.setValue(PaymentOption.PayInTaxi)
        }

        // Access the component instance
        const componentInstance = this.initPopupQRRestaurantComponent!.componentInstance;

        // Update properties directly
        componentInstance.updateComponentData(this.hotelInfo?.logoSrc);
    }

    hasMultipleTaxiCompanies(): boolean {
        if (this.taxiNames && this.taxiNames.length > 1)
            return true;
        else
            return false;
    }

    get payInTaxi() {
        if (this.taxiOrderForm.value.paymentOption == PaymentOption.PayInTaxi)
            return true;
        else return false;
    }

    get isApplyToBill() {
        if (this.taxiOrderForm.value.paymentOption == PaymentOption.ApplyToBill)
            return true;
        else return false;
    }

    get paymentOption() {
        return this.taxiOrderForm.get('paymentOption');
    }

    get pickupTime() {
        return this.taxiOrderForm.get('pickupDateTime');
    }

    get fromLocation() {
        return this.taxiOrderForm.get('fromLocation');
    }

    get destination() {
        return this.taxiOrderForm.get('destination');
    }

    get noDestinationAllowed() {
        if (this.hotelInfo == undefined) return false;
        if ((this.hotelInfo.noDestinationSettings & NoDestinationSettings.NotAllowed) == NoDestinationSettings.NotAllowed && this.hotelInfo?.selfServicePayInTaxiAllowed)
            return false;
        else
            return true;
    }

    get payInTaxiAllowed() {
        if ((this.options & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi && this.hotelInfo?.selfServicePayInTaxiAllowed)
            return true;
        else
            return false;
    }

    get localPrice() {
        if (this.payInTaxi)
            return this.priceService.taxiLocalPrice
        else
            return this.priceService.localPrice
    }

    get euroPrice() {
        if (this.payInTaxi)
            return this.priceService.taxiPriceEuro
        else
            return this.priceService.priceEuro
    }

    getOrderButtonText(): string {
        var value = this.paymentOption?.value;
        if (value === PaymentOption.ApplyToBill)
            return this.translate.instant("selfServiceStation_orderButtonText")
        else if (value === PaymentOption.PayInTaxi)
            return this.translate.instant("selfServiceStation_orderButtonText")
        else
            return this.translate.instant("selfServiceStationMobile_payButtonText")
    }

    showPaymentOptions(): boolean {
        if (!this.payInTaxiSelected && !this.noDestinationSelected)
            return true;
        else return false;
    }

    showPrice(): boolean {
        if ((!this.payInTaxiSelected && !this.noDestinationSelected) || this.priceService.isFixedDestination)
            return true;
        else return false;
    }

    //Functions
    showInit() {
        this.initPopupQRRestaurantComponent = this.dialog.open(InitPopupQRRestaurantComponent, {
            height: '99%',
            maxWidth: '99%',
            data: {
                logoSrc: this.hotelInfo?.logoSrc,
                isMobile: true,
            }
        });

        this.initPopupQRRestaurantComponent.afterClosed().subscribe((value: number) => {
            this.fromLocation?.setValue({ address: '', name: '' });
            this.destination?.setValue({ address: '', name: '' });
            this.getLocation();
            window.scrollTo(0, 0); // Scroll the page to the top, dont know why the page sometimes is futher down.

            this.whatToShow = 0;

            if (value == 1) {
                this.whatToShow = 1;
                this.onNoDestination(true);
                this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayInTaxi);
            }
            else if (value == 2)
                this.taxiOrderForm.controls.destination.setValue(this.airports![0]);
            else if (value == 3)
                this.taxiOrderForm.controls.destination.setValue({ address: this.hotelInfo?.address, name: this.hotelInfo?.name });
        });
    }

    onClick(): void {
        this.isSubmit = true;
        if (!this.taxiOrderForm.valid) {
            return;
        }
        // Price for terminal should have 2 zero more
        this.submit();
    }

    async submit(): Promise<void> {
        if (this.noDestinationSelected) {
            this.saveDirectTaxi();
            return;
        }

        if (!this.priceService.jsonWebToken) {
            throw new Error("No jsonWebToken found");
        }
        else if (!this.priceService.rideType.value) {
            throw new Error("No rideType found");
        }

        if (this.taxiOrderForm.value.paymentOption === PaymentOption.PayInTaxi) {
            this.saveOtherDestination();
            return
        }

        this.saveOtherDestinationTerminal();
    }

    setPayButtonText(value: PaymentOption) {
        if (value === PaymentOption.PayByTerminal)
            this.payInTaxiSelected = false;
        else if (value === PaymentOption.PayInTaxi)
            this.payInTaxiSelected = true;
        else if (value === PaymentOption.ApplyToBill)
            this.payInTaxiSelected = false;
    }

    setFromAddress(fromAddress: IDestination, fetchPrice = true) {
        if (!fromAddress.address) return;

        this.priceService.setFrom(fromAddress, fetchPrice);
    }

    setToAddress(toAddress: IDestination, fetchPrice = true) {
        if (!toAddress.address) return;

        this.priceService.setTo(toAddress, fetchPrice);
    }

    routeSelectorChange(value: { address: string, name: string }) {
        this.setFromAddress({ address: this.hotelInfo!.address, name: this.hotelInfo?.name }, false)
        this.setToAddress({ address: value.address, name: value.name })
        this.currentRouteAddress = value.address;
    }

    phoneSelect(event: any): void {
        this.taxiOrderForm.controls.phoneNumber.setValue(event?.tel);
    }

    onTaxiBoxClick(name: string): void {
        this.selectedTaxiCompany = name;
        this.taxiConfigurationService.updateSelectedTaxiCompany(name);
        this.priceService.setChosenTaxiCompany(name, true);
    }

    onPayInTaxiSelected($event: MatCheckboxChange): void {
        if ($event.checked)
            this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayInTaxi);
        else
            this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayByTerminal)
    }

    usingDatePicker() {
        this.taxiOrderForm.get('isAsap')?.setValue(false);
    }

    onAsap($event: MatCheckboxChange): void {
        if ($event.checked) {
            this.isAsap = true;
            this.taxiOrderForm.controls.pickupDateTime.setValue(new Date());
            this.dateChange();
        } else {
            this.isAsap = false;
        }
    }

    onNoDestinationChange($event: MatCheckboxChange): void {
        this.onNoDestination($event.checked);
    }

    onNoDestination(value: boolean): void {
        if (value) {
            if ((this.hotelInfo!.noDestinationSettings & NoDestinationSettings.WithNameAndPhone) != NoDestinationSettings.WithNameAndPhone)
                this.taxiOrderForm.controls.phoneNumber.disable();
            else
                this.taxiOrderForm.controls.phoneNumber.enable();

            this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayInTaxi);
            this.taxiOrderForm.controls.destination.disable();
            this.noDestinationSelected = true;
        }
        else {
            this.taxiOrderForm.controls.phoneNumber.enable();
            this.taxiOrderForm.controls.destination.enable();
            this.noDestinationSelected = false;
            if (!this.hotelInfo?.selfServicePayInTaxiAllowed)
                this.taxiOrderForm.controls.paymentOption.setValue(PaymentOption.PayByTerminal);
        }
    }

    dateChange(): void {
        this.selectedDate = this.taxiOrderForm.get('pickupDateTime')?.value;
        this.priceService.setSelectedDate(this.taxiOrderForm.get('pickupDateTime')?.value)
    }

    changeFromHotel(): void {
        var tempToAddress: IDestination = { address: "", name: "" }
        var tempFromAddress: IDestination = { address: "", name: "" }

        if (this.taxiOrderForm.get('fromLocation')?.value) {
            tempFromAddress = this.taxiOrderForm.get('fromLocation')!.value as IDestination;
        }
        if (this.taxiOrderForm.get('destination')?.value) {
            tempToAddress = this.taxiOrderForm.get('destination')!.value as IDestination;
        }

        this.taxiOrderForm.get("fromLocation")?.setValue(tempToAddress, { emitEvent: false });
        this.taxiOrderForm.get("destination")?.setValue(tempFromAddress, { emitEvent: false });

        this.setFromAddress(tempToAddress, false);
        this.setToAddress(tempFromAddress, false);

        this.priceService.fetchPriceInfo();
    }

    getLocation() {
        this.geolocationService.getCurrentPosition().then(
            position => {
                this.geolocationService.getClosetsAddress(position.coords.latitude, position.coords.longitude).subscribe(
                    async (data) => {
                        const from: IDestination = { address: data.address, name: data.name };
                        this.fromLocation!.setValue(from);
                        this.mobileLocation = from;
                        if (position.coords.accuracy > 50) {
                            this.presentGpsMessage(Math.trunc(position.coords.accuracy));
                        }
                    }, async (error) => {
                        if (this.destination!.value.address !== this.hotelInfo?.address || this.destination!.value.name !== this.hotelInfo?.name)
                            this.fromLocation!.setValue({ address: this.hotelInfo?.address, name: this.hotelInfo?.name });

                        this.generalErrorPopUp(error.message);
                    }
                );
            },
            async error => {
                if (this.destination!.value.address !== this.hotelInfo?.address || this.destination!.value.name !== this.hotelInfo?.name)
                    this.fromLocation!.setValue({ address: this.hotelInfo?.address, name: this.hotelInfo?.name });
            }
        );
    }

    fromHotelChange(event: MatCheckboxChange) {
        if (event.checked)
            this.fromLocation!.setValue({ address: this.hotelInfo?.address, name: this.hotelInfo?.name });
        else
            this.fromLocation!.setValue(this.mobileLocation);
    }

    /*
    async locationButtonClick() {
        var result = await this.presentAlertConfirm();
        if (result) {
            this.presentLoading("Loading...");
            this.geolocationService.getCurrentPosition().then(
                position => {
                    console.log(position);
                    this.geolocationService.getClosetsAddress(position.coords.latitude, position.coords.longitude).subscribe(async (data) => {
                        console.log(data);
                        const from: IDestination = { address: data.address, name: data.name };
                        this.taxiOrderForm.controls.fromLocation.setValue(from);
                        this.hideLoading();
                    }, async (error) => {
                        this.hideLoading();
                        this.generalErrorPopUp(error);
                    });
                },
                async error => {
                    this.hideLoading();
                    this.generalErrorPopUp(error);
                }
            );
        }
        else return;
    }
    */

    async saveOtherDestination() {
        await this.presentLoading('orderingTaxiLoader');

        const body: ISaveOtherDestinationRequest = {
            hotelId: this.hotelId,
            accessToken: this.accessToken!,
            location: this.taxiOrderForm.value.destination,
            from: this.taxiOrderForm.value.fromLocation,
            guestName: this.taxiOrderForm.value.fullName,
            phoneNumber: this.taxiOrderForm.value.phoneNumber,
            pickupTime: this.taxiOrderForm.value.pickupDateTime.toISOString(),
            orderType: OrderType.Mobile,
            paymentOption: PaymentOption.PayInTaxi,
            rideType: this.priceService.rideType.value,
            jsonWebToken: this.priceService.jsonWebToken,
            largeTaxi: false,
            numberOfGuests: 1,
            numberOfCars: 1,
            stationCar: false,
            attributesToTaxi: 0,
            comment: null,
            chosenTaxiCompany: this.selectedTaxiCompany,
        }

        this.saveTaxiService.saveOtherDestinations(body).subscribe(async () => {
            await this.hideLoading();
            const dialogRef = this.dialog.open(OrderedPopupComponent);
            dialogRef.afterClosed().subscribe(_ => {
                this.resetInterface();
            });
        }, async () => {
            await this.hideLoading();
            this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
        });
    }

    async saveOtherDestinationTerminal() {
        const paymentInfo: IPaymentInfo = {
            hotelId: this.hotelInfo!.id,
            from: this.taxiOrderForm.value.fromLocation,
            location: this.taxiOrderForm.value.destination,
            guestName: this.taxiOrderForm.value.fullName,
            phoneNumber: this.taxiOrderForm.value.phoneNumber,
            pickupTime: this.taxiOrderForm.value.pickupDateTime.toISOString(),
            jsonWebToken: this.priceService.jsonWebToken!,
            rideType: RideType.FixedPrice,
            chosenTaxiCompany: this.selectedTaxiCompany,
        };

        this.paymentApiService.createCheckout({
            currency: this.priceService.currency.value!,
            redirectUrl: this.redirectUrl!,
            paymentInfo: paymentInfo
        }).subscribe(async (data) => {
            window.location.href = data.Url;
        }, async (error) => {
            if (error.status == 400) {
                const errorAlert = await this.alertController.create({
                    header: this.translate.instant('error'),
                    subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
                    cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
                    buttons: [
                        {
                            text: 'Okay',
                            handler: () => {
                            }
                        }
                    ]
                });
                errorAlert.present();
            }
        }
        );
    }

    async saveDirectTaxi() {
        await this.presentLoading('orderingTaxiLoader');

        const body: IDirectTaxiRequest = {
            hotelId: this.hotelId,
            accessToken: this.accessToken!,
            from: this.taxiOrderForm.value.fromLocation,
            guestName: this.taxiOrderForm.value.fullName,
            phoneNumber: this.taxiOrderForm.value.phoneNumber,
            pickupTime: this.taxiOrderForm.value.pickupDateTime.toISOString(),
            numberOfCars: 1,
            stationCar: false,
            attributesToTaxi: 0,
            largeTaxi: false,
            paymentOption: PaymentOption.PayInTaxi,
            orderType: OrderType.Mobile,
            chosenTaxiCompany: this.selectedTaxiCompany,
        }

        this.saveTaxiService.directTaxiRide(body).subscribe(async () => {
            await this.hideLoading();
            const dialogRef = this.dialog.open(OrderedPopupComponent);
            dialogRef.afterClosed().subscribe(_ => {
                this.resetInterface();
            });
        }, async () => {
            await this.hideLoading();
            this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
        })
    }

    async presentGpsMessage(number: number): Promise<boolean> {
        return new Promise<boolean>(async _ => {
            const alert = await this.alertController.create({
                header: this.translate.instant('error'),
                message: this.translate.instant('gps-inaccurate').replace('{meters}', number),
                buttons: [
                    {
                        text: 'Okay',
                        handler: () => {
                        }
                    }
                ]
            });

            await alert.present();
        });
    }

    /*async presentAlertConfirm(): Promise<boolean> {
        return new Promise<boolean>(async resolve => {
            const alert = await this.alertController.create({
                header: 'Confirm!',
                message: 'Do you want to proceed?',
                buttons: [
                    {
                        text: 'No',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: () => {
                            resolve(false);
                        }
                    }, {
                        text: 'Yes',
                        handler: () => {
                            resolve(true);
                        }
                    }
                ]
            });

            await alert.present();
        });
    }*/

    resetInterface(): void {
        if (this.terminal)
            this.terminal.cancelCollectPaymentMethod();

        window.location.reload();
    }

    async presentLoading(message: string): Promise<void> {
        this.loading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: this.translate.instant(message),
            duration: 20000
        });
        await this.loading.present();
    }

    async hideLoading(): Promise<void> {
        if (!this.loading) return;
        await this.loading.dismiss();
    }

    public openErrorPopUp(taxiName: string, taxiHotelNumber: string): void {
        const dialogRef = this.dialog.open(ErrorOrderPopupComponent, {
            data: {
                taxiName,
                taxiHotelNumber
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.resetInterface();
            }
        });
    }

    public async generalErrorPopUp(message: string) {
        const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: message,
            cssClass: 'alertmessage',
            buttons: [
                {
                    text: 'Okay',
                    handler: () => {
                    }
                }
            ]
        });
        errorAlert.present();
    }

    scrollToBottom() {
        setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 500);
    }
}
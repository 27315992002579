import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HotelResponseMapperService } from 'src/app/mappers/hotel-response-mapper.service';
import { HotelSettings } from 'src/app/models/enums';
import { IHotelInfo } from 'src/app/models/hotel-info.interface';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { HotelService, HotelSystemIntegration } from 'src/app/services/hotel.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {
  hotelId: any;
  airportId: any;
  accessToken: any;
  hotelInfo?: IHotelInfo;

  showBills: Boolean = false;
  selectedTab = '';

  constructor(private parameterService: ParameterService, private router: Router, private getTaxiService: GetTaxiService, private hotelService: HotelService) {
  }

  ngOnInit(): void {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;

      if (this.hotelService.hotelInfoValue) {
        this.hotelInfo = this.hotelService.hotelInfoValue;
        if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill)
          this.showBills = true;
      }
      else {
        this.hotelService.hotelInfo.subscribe(hotelInfo => {
          this.hotelInfo = hotelInfo;
          if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill)
            this.showBills = true;
        });
      }
    });

    this.selectedTab = window.location.pathname.slice(1);
  }

  navigate(url: string) {
    let lsHI = localStorage.getItem('hotelInfo');
    let hotelInfo = JSON.parse(lsHI ? lsHI : "");
    this.router.navigateByUrl(`/${url}?hotelId=${this.hotelId}&accessToken=${this.accessToken}&airportId=${this.airportId}${(hotelInfo.terminalReaderId ? `&terminalReaderId=${hotelInfo.terminalReaderId}` : "")
      }`);
  }

  get hideCO2Shop() {
    return ((this.hotelInfo?.hotelSettings ?? 0) & HotelSettings.HideCO2Shop) == HotelSettings.HideCO2Shop;
  }
}

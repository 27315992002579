<div class="content">
    <div class="products" *ngFor="let product of products">
        <div class="title">
            {{product.name}}
        </div>
        <div class="products-text">
            <div><strong>{{ "co2ProductAmount" | translate }} </strong></div>
            <div>{{product.amount}}</div>
        </div>
        <div class="products-text">
            <div><strong>{{ "co2ProductCost" | translate }}</strong></div>
            <div>{{product.coinCost}} {{ "CO2Coins" | translate }}</div>
        </div>
        <div class="products-text">
            <div><strong>{{ "co2ProductAmountCoinCost" | translate }}</strong></div>
            <div>{{product.amount * product.coinCost}} {{ "CO2Coins" | translate }}</div>
        </div>
        <hr class="divider" />
    </div>
    <div class="totalCO2Coins title"> {{ "totalCO2Coins" | translate }} {{total}} {{ "CO2Coins" | translate }}</div>
    <div class="choiceBox">
        <div class="title"> {{ "choiceTitle" | translate }} </div>
        <div class="choiceBoxButtonBox">
            <button class="choiceBoxButton yes" (click)="yes()"> {{ "yes" | translate }} </button>
            <br>
            <button class="choiceBoxButton no" (click)="no()"> {{ "no" | translate }} </button>
        </div>
    </div>
</div>

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingController, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { IBoughtProducts, IProduct } from 'src/app/models/co2Products.interface';
import { CO2Service } from 'src/app/services/co2.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { Co2ContactInformationComponent } from 'src/app/shared/components/co2-contact-information/co2-contact-information.component';
import { ConfirmCo2Component } from 'src/app/shared/components/confirm-co2/confirm-co2.component';

@Component({
  selector: 'app-co2shop',
  templateUrl: './co2shop.component.html',
  styleUrls: ['./co2shop.component.scss']
})
export class Co2shopComponent implements OnInit {
  lang: string | undefined = localStorage.getItem("lang") ?? "en";
  hotelId: any;
  accessToken: any;
  loading!: HTMLIonLoadingElement;
  showConfirmed: boolean = false;
  hotelCO2Coins: number = 0;
  noProductsSelected: boolean = false;
  productsBought: IProduct[] = []
  productsSelected: IProduct[] = []
  phoneRequired: boolean = false;
  phone: string | null = null;
  emailRequired: boolean = false;
  email: string | null = null;
  amountTooHigh: boolean = false;

  notReleasedYet: boolean = true; //Defines if the shop is open.

  products: IProduct[] = [];

  constructor(private parameterService: ParameterService, private translateService: TranslateService, public loadingController: LoadingController, private alertController: AlertController, private co2Service: CO2Service, private dialog: MatDialog) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;
    });
  }

  ngOnInit(): void {
    this.getProducts();
  }

  async getProducts() {
    await this.presentLoading()
    this.co2Service.getCO2Products().subscribe(async (data: any) => {
      this.hotelCO2Coins = data.cO2CoinsEarned;
      if (data.cO2Products) {
        this.products = this.mapToProducts(data.cO2Products);
        this.notReleasedYet = false;
      }
      await this.hideLoading();
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translateService.instant('error'),
          subHeader: this.translateService.instant(`backendStatusCode${error.error.statusCode}`),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translateService.instant('error'),
          subHeader: this.translateService.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });
  }

  async confirmOrder() {
    this.productsBought = [...this.products]

    for (let i = 0; i < this.productsBought.length;) {
      if (this.productsBought[i].amount == 0)
        this.productsBought.splice(i, 1);
      else {
        if (this.productsBought[i].amount > this.productsBought[i].inStock) {
          this.amountTooHigh = true;
          return;
        }
        if (this.productsBought[i].requiresPhoneNumber)
          this.phoneRequired = true;
        if (this.productsBought[i].requiresEmail)
          this.emailRequired = true;
        i++
      }
    }

    this.amountTooHigh = false;

    if (this.productsBought.length == 0) {
      this.noProductsSelected = true;
      return;
    }
    else
      this.noProductsSelected = false;

    const co2Confirm = this.dialog.open(ConfirmCo2Component, {
      disableClose: true, data: {
        products: this.productsBought
      }
    });

    co2Confirm.afterClosed().subscribe((result) => {
      if (!result) {
        this.resetRequirments();
        return
      }

      const phoneEmailConfirm = this.dialog.open(Co2ContactInformationComponent, {
        disableClose: true, data: {
          phoneRequired: this.phoneRequired,
          emailRequired: this.emailRequired
        }
      })
      phoneEmailConfirm.afterClosed().subscribe((result) => {
        if (result) {
          this.phone = result.phoneNumber;
          this.email = result.email;
          this.buyProducts();
        }
        else {
          this.resetRequirments();
          return
        }
      })
    });
  }

  buyProducts() {
    this.presentLoading();

    var body: IBoughtProducts = {
      email: this.email,
      phoneNumber: this.phone,
      boughtProducts: this.productsBought
    }

    this.co2Service.co2ProductBought(body).subscribe(async (data) => {
      await this.hideLoading()
      this.showConfirmed = true;
      setTimeout(async () => {
        this.resetRequirments();
        window.location.reload();
      }, 5000);
    }, async (error) => {
      this.resetRequirments();
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translateService.instant('error'),
          subHeader: this.translateService.instant(`backendStatusCode${error.error.statusCode}`),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translateService.instant('error'),
          subHeader: this.translateService.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });
  }

  amountIncrease(id: string) {
    const elementId = "product" + id;
    var input = (<HTMLInputElement>document.getElementById(elementId));
    var amount: number = +input.value;
    amount++;
    this.setAmount(amount, id);
    input.value = amount.toString();
  }

  amountDecrease(id: string) {
    const elementId = "product" + id;
    var input = (<HTMLInputElement>document.getElementById(elementId));
    var amount: number = +input.value;
    if (amount > 0) {
      amount--;
      this.setAmount(amount, id);
      input.value = amount.toString();
    }
  }

  onChangeAmount(event: Event, id: string) {
    var amount = (event.target as HTMLInputElement).value;
    this.setAmount(parseInt(amount), id);
  }

  setAmount(amount: number, id: string) {
    var index = this.products.findIndex(obj => obj.productId === id);
    this.products[index].amount = amount;
    this.productsSelected = this.products.filter(item => item.amount > 0);
  }

  mapToProducts(data: any): IProduct[] {
    var returnProducts: IProduct[] = [];

    for (let i = 0; i < data.length; i++) {
      var inStockAmount = 99;
      if (data[i].inStock)
        inStockAmount = data[i].inStock

      var product: IProduct = {
        productId: data[i].productId,
        name: data[i].name,
        coinCost: data[i].coinCost,
        imageSrc: data[i].imageSrc,
        amount: 0,
        requiresPhoneNumber: data[i].requiresPhoneNumber,
        requiresEmail: data[i].requiresEmail,
        inStock: inStockAmount
      }
      returnProducts.push(product);
    }

    return returnProducts;
  }

  get total() {
    var totalReturn = 0;
    this.products.forEach(product => {
      totalReturn += product.amount * product.coinCost;
    });
    return totalReturn
  }

  resetRequirments() {
    this.phoneRequired = false;
    this.emailRequired = false;
    this.showConfirmed = false;
    this.amountTooHigh = false;
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translateService.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }
}

<div class="gfg">
    <app-header [logoSrc]="logoSrc" [gdpr]="true"></app-header>

    <div class="content">
        <div class="top-txt-box margin-bottom">
            <img id="picture" class="margin-bottom" src="/assets/images/taxi-ordering.png">
            <div class="top-txt"> {{ 'selfServiceStation_topText' | translate }}</div>
        </div>
    
        <div class="button-box">
            <div class="button-row">
                <button mat-flat-button class="button bottom-margin" (click)="continue(1)" color="primary">
                    {{ 'selfServiceStation_orderTaxiNow' | translate }}
                </button>
                <button mat-flat-button class="button bottom-margin" (click)="continue(0)" color="primary"> 
                    {{ 'selfServiceStation_orderTaxiFixedPrice' | translate }} 
                </button>
            </div>
            <div class="button-row">
                <button *ngIf="showLocationsOverAirport() == 0" mat-flat-button class="button" (click)="continue(2)" color="primary">
                    {{ 'selfServiceStation_orderTaxiAirport' | translate }} 
                    <br *ngIf="airportPrice"/>        
                    {{ getAirportPrice() }} 
                </button>
                <button *ngIf="showLocationsOverAirport() == 1" mat-flat-button class="button" (click)="continue(4)" color="primary"> 
                    {{ getLocationTranslation(1) }}
                    <br *ngIf="secondPrice"/>        
                    {{ getLocationPrice(1) }}
                </button>
                <button *ngIf="locations" mat-flat-button class="button" (click)="continue(3)" color="primary"> 
                    {{ getLocationTranslation(0) }}  
                    <br *ngIf="firstPrice"/>           
                    {{ getLocationPrice(0) }} 
                </button>
            </div>
        </div>
    </div>

    <app-bottom-banner class="bottom" [logoSrc]="logoSrc"></app-bottom-banner>
</div>
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HotelService } from 'src/app/services/hotel.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  slideItem = 'one';
  hotelId: any;
  airportId: any;
  flightDate: any;
  bookingNumber: any;
  guestName: any;
  lang: any;

  constructor(private router: Router, private translate: TranslateService, private route: ActivatedRoute, private hotelService: HotelService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.hotelId = params['hotelId'];
      this.airportId = params['airportId'];
      this.flightDate = params['flightDate'];
      this.bookingNumber = params['bookingNumber'];
      this.guestName = params['guestName'];
    });

    this.lang = localStorage.getItem("lang");

    if (this.hotelService.hotelInfoValue) {
      this.updateLang(this.hotelService.hotelInfoValue.language);
    }
    else {
      this.hotelService.hotelInfo.subscribe(hotelInfo => {
        this.updateLang(hotelInfo.language);
      });
    }

    if (!this.lang) {
      this.translate.setDefaultLang('en');
      localStorage.setItem("lang", 'en');
    }
  }

  updateLang(lang: string) {
    this.lang = lang;
    if (lang == 'se')
      this.translate.setDefaultLang('en');
    else
      this.translate.setDefaultLang(lang);
    localStorage.setItem("lang", 'en');
  }

  open() {
    // this.router.navigateByUrl('/order-taxi')
    this.router.navigateByUrl('/order-taxi?hotelId=' + this.hotelId +
      '&airportId=' + this.airportId + '&flightDate=' + (this.flightDate ?? '') + '&bookingNumber=' + (this.bookingNumber ?? '')
      + '&guestName=' + (this.guestName ?? ''));
  }
}

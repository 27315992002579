<ion-content>
    <app-header></app-header>
    
    <div class="center" *ngIf="notReleasedYet">
        <p>CO<sub>2</sub> shop coming soon.</p>
    </div>

    <div class="content" *ngIf="showConfirmed">    
        <div class="confirmed">
            {{'thanksForYourOrder' | translate}}
        </div>
    </div>

    <div class="new" *ngIf="!showConfirmed && !notReleasedYet">
        <div class="left-container">
            <div class="products" *ngFor="let product of products">
                <div class="imageContainer">
                    <img src=".\assets\images\{{product.imageSrc}}" class="productImage">
                </div>
                <div class="productTitle">
                    {{product.name}}
                </div>
                <div class="productTitle">
                   {{ "coinCost" | translate }} {{product.coinCost}} {{ "CO2Coins" | translate }}
                </div>
                <div *ngIf="product.inStock != 99" class="productTitle">
                    {{ "inStock" | translate }} {{product.inStock}}
                 </div>
                <div class="counterBox">
                    <button mat-stroked-button class="amountBtn" (click)="amountDecrease(product.productId)">-</button>
                    <input class="inputAmount" type="number" id="product{{product.productId}}" min="0" [defaultValue]="0" (change)="onChangeAmount($event, product.productId)">
                    <button mat-stroked-button class="amountBtn" (click)="amountIncrease(product.productId)">+</button>
                </div>  
            </div>
        </div>
        
        <div class="right-container">
            <div class="co2-container">
                <div class="co2-header">
                    <span class="co2-title"><strong>{{ "hotelCO2Coins" | translate }}</strong></span>
                    <span class="co2-value">{{hotelCO2Coins}} {{ "CO2Coins" | translate }}</span>
                </div>
                
                <hr class="divider" />
                
                <div class="co2-items">
                    <div class="co2-item" *ngFor="let product of productsSelected">
                        <span class="item-name"><strong>{{product.name}}</strong></span>
                        <span class="item-value">{{product.amount * product.coinCost}} {{ "CO2Coins" | translate }}</span>
                    </div>
                </div>
                
                <hr class="divider" *ngIf="productsSelected.length > 0" />
                
                <div class="co2-total">
                    <span class="total-title"><strong>{{ "totalCO2Coins" | translate }}</strong></span>
                    <span class="total-value">{{total}} {{ "CO2Coins" | translate }}</span>
                </div>

                <div *ngIf="noProductsSelected" class="error"> {{ "noProductsSelected" | translate }} </div>
                <div *ngIf="amountTooHigh" class="error"> {{ "amountTooHigh" | translate }} </div>
                <button class="order-button" (click)="confirmOrder()"> {{ "orderCO2Button" | translate }} </button>
            </div>
        </div>
    </div>
</ion-content>

import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SaveTaxiService } from '../../services/save-taxi.service';
import { GetTaxiService } from '../../services/get-taxi.service';
import { PriceService } from 'src/app/services/price.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { HotelService, PaymentOption } from 'src/app/services/hotel.service';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ErrorOrderPopupComponent } from 'src/app/shared/components/error-order-popup/error-order-popup.component';
import { ParameterService } from 'src/app/services/parameter.service';
import { OrderType } from 'src/app/enums/order-type.enum';
import { AttributesToTaxi } from 'src/app/services/ride.service';
import { LogService } from 'src/app/services/log.service';
import { IHotelInfo } from 'src/app/models/hotel-info.interface';
import { HotelResponseMapperService } from 'src/app/mappers/hotel-response-mapper.service';
import { IDirectTaxiRequest } from 'src/app/models/ride/directtaxi.interface';
import { ICoordinate } from 'src/app/models/coordinate.interface';
import { takeUntil } from 'rxjs/operators';
import { CancelledByDriver, RideType, TaxiStatus } from 'src/app/models/enums';
import { IRideBasic } from 'src/app/services/models/ride.model';
import { IDestination } from "src/app/shared/models/destination.interface";
import { DatePipe } from '@angular/common';
import { PauseScreenComponent } from 'src/app/shared/components/pause-screen/pause-screen.component';

@Component({
  selector: 'app-taxi-assistant',
  templateUrl: './taxi-assistant.component.html',
  styleUrls: ['./taxi-assistant.component.scss'],
  providers: [DatePipe]
})
export class TaxiAssistantComponent implements OnInit, OnDestroy {
  loading!: HTMLIonLoadingElement;

  paymentOptionsSubscription?: Subscription;
  hotelInfo?: IHotelInfo;
  subscr$ = new Subject();

  public orderFormGroup: FormGroup;
  hotelId: any;
  accessToken: any;
  logoSrc?: string;
  taxiCars: ICoordinate[] = [];
  hotelLocation: ICoordinate | undefined;
  availableOptions: string[] = [];

  today = new Date();
  rides: IRideBasic[] = [];
  upcomingRidesTimer?: number;
  subject = new Subject();

  hideTop: boolean = false;
  hideBottom: boolean = false;

  @ViewChild('pauseScreen') pauseScreen!: PauseScreenComponent;
  private timeoutId: any;
  private readonly INACTIVITY_TIME = 15 * 60 * 1000; // 5 minutes in milliseconds
  public isActivityCheckEnabled = true; // Set to false to disable the check

  constructor(private fb: FormBuilder, public dialog: MatDialog, private alertController: AlertController, public translate: TranslateService, private getTaxiService: GetTaxiService, private saveTaxiService: SaveTaxiService, public loadingController: LoadingController, private parameterService: ParameterService, private priceService: PriceService, private taxiConfigurationService: TaxiConfigurationService, private log: LogService, private readonly changeDetectorRef: ChangeDetectorRef, private datePipe: DatePipe, private hotelService: HotelService) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;
    });

    if (this.hotelService.hotelInfoValue) {
      this.hotelInfo = this.hotelService.hotelInfoValue;
      this.priceService.setFromHotelAddress();
      this.logoSrc = this.hotelInfo.logoSrc;
      this.hotelLocation = { lng: this.hotelInfo.longitude, lat: this.hotelInfo.latitude, minutesAway: 0, additionalGuestInfo: '', id: '' }
    }
    else {
      this.hotelService.hotelInfo.subscribe(hotelInfo => {
        this.hotelInfo = hotelInfo;
        this.priceService.setFromHotelAddress();
        this.logoSrc = this.hotelInfo.logoSrc;
        this.hotelLocation = { lng: this.hotelInfo.longitude, lat: this.hotelInfo.latitude, minutesAway: 0, additionalGuestInfo: '', id: '' }
      });
    }

    this.orderFormGroup = fb.group({
      from: [null],
      guestName: [null],
      pickupTime: [new Date(), Validators.required],
      numberOfCars: ['1', [Validators.min(1), Validators.max(25)]],
      numberOfGuests: [3],
      paymentOption: [PaymentOption.PayInTaxi, Validators.required],
      taxiPrice: [0],
      largeTaxi: [false],
      phoneNo: [null, [Validators.minLength(8), Validators.pattern(/^((00|\+)\d{8,16})$/)]]
    });

    this.numberOfCars?.valueChanges.subscribe(_ => {
      this.priceService.setNumberOfCars(this.numberOfCars?.value);
    })

    priceService.taxiPrice.subscribe(price => {
      this.taxiPrice?.setValue(0);
      if (price === undefined) { }
      else {
        this.taxiPrice?.setValue(price.customerPrice);
      }
    });

    this.taxiConfigurationService.taxiAvailableOptions.subscribe((data) => {
      this.availableOptions = data;
    });

    this.startInactivityTimer();
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();

    this.updateRidesTimer();
  }

  ngOnDestroy(): void {
    this.paymentOptionsSubscription?.unsubscribe();

    this.subscr$.next();
    this.subscr$.complete();

    clearTimeout(this.upcomingRidesTimer);
  }

  formSubmit: boolean = false;

  async submit() {
    this.log.information("Ordering taxi with hotelId: " + this.hotelId);
    await this.presentLoading();

    // I am saving the initials no matter what. Even if the validation (or request) fails,
    // I expect that the same receptionst will be present

    this.formSubmit = true;

    this.paymentOption!.updateValueAndValidity();
    if (!this.orderFormGroup.valid) {
      await this.hideLoading();
      return;
    }

    const body: IDirectTaxiRequest = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      roomNumber: undefined,
      guestName: this.guestName?.value,
      numberOfCars: 1,
      phoneNumber: this.phoneNo?.value,
      pickupTime: this.pickupTime!.value.toISOString(),
      stationCar: false,
      paymentOption: PaymentOption.PayInTaxi,
      largeTaxi: this.largeTaxi?.value,
      orderType: OrderType.Assistant,
      attributesToTaxi: AttributesToTaxi.None,
      numberOfGuests: 3
    }

    this.saveTaxiService.directTaxiRide(body).subscribe(async (data) => {
      await this.hideLoading();
      this.pickupTime?.setValue(new Date())
      this.guestName?.setValue(null)
      this.updateRides();
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'newalertmessage' : !error.error.statusCode ? 'newalertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'newalertmessage' : 'newalertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'newalertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });
  }

  async cancelRide(ride: IRideBasic) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      let body = {
        id: ride.id
      }

      await this.presentLoading()

      this.getTaxiService.cancelRide(body).subscribe(async (data) => {
        await this.hideLoading()

        var textKey = this.translate.instant('backendStatusCodeTaxiCouldNotBeCancelled').replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue);
        if (data.taxiIsCancelled) {
          textKey = this.translate.instant('taxi-has-been-cancelled');
          ride.taxiStatus = TaxiStatus.Cancelled;
        }

        const alert = await this.alertController.create({
          header: this.translate.instant('taxiStatusHeader'),
          subHeader: textKey,
          cssClass: 'newalertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ],
          backdropDismiss: false
        });
        await alert.present();

        const result = await alert.onDidDismiss();
        if (result)
          this.updateRides();

      }, async (error) => {
        await this.hideLoading()
        if (error.status == 400) {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
            cssClass: !error.error ? 'newalertmessage' : !error.error.statusCode ? 'newalertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'newalertmessage' : 'newalertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
        else {
          const alert = await this.alertController.create({
            header: "Error Occurred",
            subHeader: this.translate.instant('500errorMsg'),
            cssClass: 'newalertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ],
            backdropDismiss: false
          });
          await alert.present();
        }
      })
    });
  }

  updateRidesTimer() {
    const upcomingRidesSubscription = this.getTaxiService.getRestaurantRides(this.hotelId);

    upcomingRidesSubscription.pipe(takeUntil(this.subject)).subscribe((rides: Array<any>) => {
      if (rides.length === 0) {
        this.rides = [this.createEmptyRide(), this.createEmptyRide(), this.createEmptyRide()];
        this.isActivityCheckEnabled = true;
      } else if (rides.length === 1) {
        this.rides = [rides[0], this.createEmptyRide(), this.createEmptyRide()];
        this.isActivityCheckEnabled = false;
      } else if (rides.length === 2) {
        this.rides = [rides[0], rides[1], this.createEmptyRide()];
        this.isActivityCheckEnabled = false;
      } else {
        this.rides = rides;
        this.isActivityCheckEnabled = false;

        this.rides.sort((a, b) => a.pickupTime > b.pickupTime ? 1 : -1);
      }

      if (rides.length === 4) {
        this.hideTop = false;
        this.hideBottom = true;
      }
      else if (rides.length >= 5) {
        this.hideTop = true;
        this.hideBottom = true;
      }
      else {
        this.hideTop = false;
        this.hideBottom = false;
      }

      this.today = new Date();

      this.resetTimer();
      this.upcomingRidesTimer = window.setTimeout(() => this.updateRidesTimer(), 30000); //Remmber to revert 30000
    },
      error => {
        this.upcomingRidesTimer = window.setTimeout(() => this.updateRidesTimer(), 60000);
      });
  }

  updateRides() {
    this.getTaxiService.getRestaurantRides(this.hotelId).subscribe(async (rides) => {
      if (rides.length === 0) {
        this.rides = [this.createEmptyRide(), this.createEmptyRide(), this.createEmptyRide()];
        this.isActivityCheckEnabled = true;
      } else if (rides.length === 1) {
        this.rides = [rides[0], this.createEmptyRide(), this.createEmptyRide()];
        this.isActivityCheckEnabled = false;
      } else if (rides.length === 2) {
        this.rides = [rides[0], rides[1], this.createEmptyRide()];
        this.isActivityCheckEnabled = false;
      } else {
        this.rides = rides;
        this.isActivityCheckEnabled = false;

        this.rides.sort((a, b) => a.pickupTime > b.pickupTime ? 1 : -1);
      }

      if (rides.length === 4) {
        this.hideTop = false;
        this.hideBottom = true;
      }
      else if (rides.length >= 5) {
        this.hideTop = true;
        this.hideBottom = true;
      }
      else {
        this.hideTop = false;
        this.hideBottom = false;
      }
    },
      error => { });
  }

  phoneSelect(event: any): void {
    this.phoneNo?.setValue(event?.tel);
  }

  showPhone(): boolean {
    if (this.hotelInfo?.country != 'Finland')
      return false;

    const userAgent = navigator.userAgent;

    // Regular expression to detect mobile devices (phone/tablet)
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    // If not mobile, then it's likely a desktop
    return !isMobile;
  }

  getEtaText(eta: any): string {
    if (eta)
      return eta + " min."
    else return ""
  }

  getPickUpTime(pickUpTime: string): string {
    if (pickUpTime == "")
      return "";

    const date = new Date(pickUpTime);

    if (date.getDay() == this.today.getDay())
      return this.datePipe.transform(pickUpTime + 'Z', 'HH:mm') ?? "";
    else
      return this.datePipe.transform(pickUpTime + 'Z', 'd/M HH:mm') ?? "";
  }

  getTaxiStatusText(ride: IRideBasic) {
    if (ride.pickupTime == "")
      return "";

    if (ride.cancelledByDriver != CancelledByDriver.False)
      return this.translate.instant('shortTaxiStatusInVain')

    if (ride.taxiStatus === TaxiStatus.Ordered)
      return this.translate.instant('shortTaxiStatusOrdered');

    if (ride.taxiStatus === TaxiStatus.OnItsWay)
      return this.translate.instant('shortTaxiStatusOnItsWay');

    if (ride.taxiStatus === TaxiStatus.TaximeterStarted)
      return this.translate.instant('shortTaxiStatusTaximeterStarted');

    if (ride.taxiStatus === TaxiStatus.Cancelled)
      return this.translate.instant('shortTaxiStatusCancelled');

    if (ride.taxiStatus === TaxiStatus.Completed)
      return this.translate.instant('shortTaxiStatusCompleted');

    if (ride.taxiStatus === TaxiStatus.InVain)
      return this.translate.instant('shortTaxiStatusInVain');

    if (ride.taxiStatus === TaxiStatus.NoShow)
      return this.translate.instant('shortTaxiStatusNoShow');

    if (ride.taxiStatus === TaxiStatus.Arrived)
      return this.translate.instant('shortTaxiStatusArrived');

    if (ride.taxiStatus == TaxiStatus.Unknown)
      return this.translate.instant('shortTaxiStatusUnknown');

    return '';
  }

  getTaxiStatusClass(ride: { taxiStatus: TaxiStatus }): string {
    switch (ride.taxiStatus) {
      case TaxiStatus.OnItsWay:
        return 'on-its-way';
      case TaxiStatus.Arrived:
        return 'on-its-way';
      case TaxiStatus.Cancelled:
        return 'cancelled';
      case TaxiStatus.InVain:
        return 'in-vain';
      case TaxiStatus.NoShow:
        return 'in-vain';
      case TaxiStatus.Unknown:
        return 'unknown';
      default:
        return ''; // Default class if status doesn't match any case
    }
  }

  statusOnItsWay(ride: IRideBasic) {
    return ride.taxiStatus === TaxiStatus.OnItsWay;
  }

  statusTaximeterStarted(ride: IRideBasic) {
    return ride.taxiStatus === TaxiStatus.TaximeterStarted;
  }

  statusInVain(ride: IRideBasic) {
    return ride.taxiStatus === TaxiStatus.InVain;
  }

  statusUnknown(ride: IRideBasic) {
    return ride.taxiStatus === TaxiStatus.Unknown;
  }

  statusArrived(ride: IRideBasic) {
    return ride.taxiStatus === TaxiStatus.Arrived;
  }

  statusCancelled(ride: IRideBasic) {
    return ride.taxiStatus === TaxiStatus.Cancelled;
  }

  statusNoShow(ride: IRideBasic) {
    return ride.taxiStatus === TaxiStatus.NoShow;
  }

  statusCompleted(ride: IRideBasic) {
    return ride.taxiStatus === TaxiStatus.Completed;
  }

  statusOrdered(ride: IRideBasic) {
    return ride.taxiStatus === TaxiStatus.Ordered;
  }

  get phoneNo() {
    return this.orderFormGroup.get('phoneNo');
  }

  get numberOfCars() {
    return this.orderFormGroup.get('numberOfCars');
  }

  get from() {
    return this.orderFormGroup.get('from');
  }

  get pickupTime() {
    return this.orderFormGroup.get('pickupTime');
  }

  get largeTaxi() {
    return this.orderFormGroup.get('largeTaxi');
  }

  get paymentOption() {
    return this.orderFormGroup.get('paymentOption');
  }

  get taxiPrice() {
    return this.orderFormGroup.get('taxiPrice');
  }

  get guestName() {
    return this.orderFormGroup.get("guestName");
  }

  public displayOption(optionName: string): boolean {
    return this.availableOptions.some(option => option.toUpperCase() === optionName.toUpperCase());
  }

  public activeCancel(ride: IRideBasic) {
    return !this.statusTaximeterStarted(ride)
      && !this.statusUnknown(ride)
      && !this.statusInVain(ride)
      && !this.statusNoShow(ride)
      && !this.statusCancelled(ride)
      && !this.statusCompleted(ride)
  }

  paymentOptionFilter(option: PaymentOption) {
    if ((option & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal) {
      return PaymentOption.PayByTerminal
    }
    return option;
  };

  paymentOptionFilterIntegration(option: PaymentOption) {
    if ((option & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill) {
      return PaymentOption.ApplyToBill
    }
    return option;
  };

  private createEmptyRide(): IRideBasic {
    var tempFrom: IDestination = { address: "", name: "" }
    var tempTo: IDestination = { address: "", name: "" }

    return {
      id: "",
      guestName: "",
      phoneNumber: "",
      from: tempFrom,
      to: tempTo,
      pickupTime: "",
      rideType: RideType.FromAirport,
      taxiStatus: TaxiStatus.Unknown,
      orderType: OrderType.Hotel,
      taxiCarId: "",
      etaOfTaxi: undefined,
      cancelledByDriver: CancelledByDriver.False
    };
  }

  private startInactivityTimer(): void {
    this.resetTimer();

    window.addEventListener('mousemove', () => this.resetTimer());
    window.addEventListener('keydown', () => this.resetTimer());
    window.addEventListener('click', () => this.resetTimer());
    window.addEventListener('touchstart', () => this.resetTimer());
  }

  private resetTimer(): void {
    if (this.isActivityCheckEnabled) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => this.showPauseScreen(), this.INACTIVITY_TIME);
    }
  }

  private showPauseScreen(): void {
    if (this.isActivityCheckEnabled && this.pauseScreen) {
      this.pauseScreen.show();
    }
  }

  handleResume(): void {
    this.resetTimer(); // Restart the inactivity timer when the user resumes
  }

  public openErrorPopUp(taxiName: string, taxiHotelNumber: string): void {
    this.dialog.open(ErrorOrderPopupComponent, {
      data: {
        taxiName,
        taxiHotelNumber
      }
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'newalertmessage',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  public onChangeDate(): void {
    this.priceService.setSelectedDate(this.pickupTime?.value);
  }
}
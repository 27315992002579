import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SaveTaxiService } from '../../services/save-taxi.service';
import { GetTaxiService } from '../../services/get-taxi.service';
import { PriceService } from 'src/app/services/price.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { ValidationTools, locationValidator, logInvalidControls } from 'src/tools/ValidationTools';
import { ReceptionistInitialsService } from 'src/app/services/receptionist-initials.service';
import { ReceiptService } from 'src/app/services/receipt.service';
import { HotelService, HotelSystemIntegration, NoDestinationSettings, PaymentOption } from 'src/app/services/hotel.service';
import { Subject, Subscription, interval } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LargeTaxiPopupComponent } from '../large-taxi-popup/large-taxi-popup.component';
import { PaymentApiService } from 'src/app/services/api/payment-api.service';
import { ErrorOrderPopupComponent } from 'src/app/shared/components/error-order-popup/error-order-popup.component';
import { PaidPopupComponent } from 'src/app/shared/components/paid-popup/paid-popup.component';
import { CollectPaymentPopupComponent } from 'src/app/shared/components/collect-payment-popup/collect-payment-popup.component';
import { ParameterService } from 'src/app/services/parameter.service';
import { CollectPaymentMethodResponse } from 'src/app/models/payment/terminal.interface';
import { IPaymentIntentResponse } from 'src/app/models/payment-intent-response.interface';
import { OrderType } from 'src/app/enums/order-type.enum';
import { IDestination } from 'src/app/shared/models/destination.interface';
import { HotelSettings, RideType, SearchEngine } from 'src/app/models/enums';
import { AttributesToTaxi, RideService } from 'src/app/services/ride.service';
import { DriverComments } from 'src/app/services/models/driverComments.model';
import { LogService } from 'src/app/services/log.service';
import { IHotelInfo } from 'src/app/models/hotel-info.interface';
import { ICreatePaymentIntentRequest } from 'src/app/models/payment/createIntentRequest.interface';
import { ISaveOtherDestinationTerminalRequest } from 'src/app/models/ride/save-otherdestination-terminal.interface';
import { GetHotelSystem } from 'src/app/services/get-hotel.service';
import { HotelSystemCustomer } from 'src/app/models/hotel-system-intergration/hotel-system-customer.interface';
import { RoomNumberIncorrect } from 'src/app/shared/components/room-number-incorrect-popup/room-number-incorrect-popup.component';
import { IAddress } from 'src/app/models/address.interface';
import { ChooseWhichCommentComponent } from 'src/app/shared/components/choose-which-comment/choose-which-comment.component';
import { SpecialHotelInformationPopup } from 'src/app/shared/components/special-hotel-info-popup/special-hotel-info-popup.component';
import { ISaveOtherDestinationRequest } from 'src/app/models/ride/save-otherdestination.interface';
import { IDirectTaxiRequest } from 'src/app/models/ride/directtaxi.interface';
import { debounceTime } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { IFromAirportRideRequest } from 'src/app/models/ride/from-airport.interface';

@Component({
  selector: 'app-reception-platform',
  templateUrl: './reception-platform.component.html',
  styleUrls: ['./reception-platform.component.scss']
})
export class ReceptionPlatformComponent implements OnInit, OnDestroy {
  availableOptions: string[] = [];

  step = 5 / 60;
  loading!: HTMLIonLoadingElement;

  defaultCommentToTaxiDriverSubscription?: Subscription;
  paymentOptionsSubscription?: Subscription;
  hotelInfo?: IHotelInfo;
  subscr$ = new Subject();
  public secondDestination: FormGroup;
  hotelId: any;
  airportId: any;
  terminalReaderId: any;
  accessToken: any;
  otherDestinations: (IDestination & { detailedAddress?: IAddress })[] = [];
  messageShow: boolean = false;
  showEmote: boolean = false;
  showLargeTaxiLoading: boolean = false;
  canOrderLargeTaxi = true;
  driverComments?: DriverComments;
  hotelSystemIntegrationApplyToBill = false;
  hideNameAndPhone = false;
  showApplyToBill = false;
  noDestinationAllowed = false;
  noDestinationSelected = false;
  getCustomer = false;
  progressValue: number = 0;
  timeOutTimer: number = 5000;
  largeTaxiCarResponse: string = "";
  loadingDone: boolean = false;
  taxiBookingId: string = "";
  paymentOptionPayByTerminal: boolean = false;
  toHotelActive: boolean = false;
  phoneNumber: any = { phoneNumber: "", countryCode: "" };
  boldCount: number = 0;
  getOtherAmount: number = 8;
  hideEverything: boolean = false;
  largeTaxiPopupOpen: boolean = false;
  showOption: boolean = false;
  showAirports: boolean = false;
  airports?: { id: string, name: string, address: string }[];
  selectedAirport?: { id: string, name: string, address: string };
  isLogoAvailable: { [key: string]: boolean } = {};
  taxiNames: string[] = [];
  selectedTaxiCompany: string | undefined;
  spacing: string = ", "; // For seperating roomnumber and name
  noDestChecked: boolean = false;
  formSubmit: boolean = false;
  numberOfCarsSelectionOptions: number[] = [1, 2, 3, 4];
  validated: boolean = false;
  showOnLarge: boolean = false;
  paymentIntentId?: string; //This is used to parse through to the goSaveOtherDestinations method to save the other destinations but with the same paymentIntentId as when trying to payByTerminal
  resetTimer: any;
  popUpTimer: any;
  private lastActivityTime: number;

  constructor(private tools: ValidationTools, private fb: FormBuilder, public dialog: MatDialog, private alertController: AlertController,
    private paymentApiService: PaymentApiService, public translate: TranslateService, private getTaxiService: GetTaxiService, private saveTaxiService: SaveTaxiService,
    private getHotelSystem: GetHotelSystem, public loadingController: LoadingController, private receiptService: ReceiptService, private hotelService: HotelService,
    private parameterService: ParameterService, private priceService: PriceService, private taxiConfigurationService: TaxiConfigurationService, private log: LogService,
    private rideService: RideService, public receptionistInitialsService: ReceptionistInitialsService, private readonly cdr: ChangeDetectorRef) {

    this.secondDestination = fb.group({
      destination: [null, [Validators.required, locationValidator]],
      from: [null],
      roomNo: [null],
      commentToDriver: [null],
      guestName: [null],
      phoneNo: [null, [Validators.minLength(8), Validators.pattern(/^((00|\+)\d{8,16})$/)]],
      pickupTime: [new Date(), Validators.required],
      largeTaxi: [false],
      numberOfCars: ['1', [Validators.min(1), Validators.max(25)]],
      stationCar: [false],
      animal: [false],
      bike: [false],
      electricCar: [false],
      numberOfGuests: [2],
      paymentOption: [PaymentOption.PayInTaxi, Validators.required],
      taxiPrice: [0],
      carseat: [false],
      oneBoosterSeat: [false],
      twoBoosterSeats: [false],
      childSeat: [false],
      hybridCar: [false],
      flightNo: [null]
    });

    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;
      this.terminalReaderId = params.terminalReaderId;

      if (this.hotelService.hotelInfoValue) {
        this.hotelInfo = this.hotelService.hotelInfoValue;
        this.updateHotelInfo()
      }
      else {
        this.hotelService.hotelInfo.subscribe(hotelInfo => {
          this.hotelInfo = hotelInfo;
          this.updateHotelInfo()
        });
      }

      getTaxiService.getAirportsForHotel(this.hotelId!)
        .subscribe(airports => {
          this.airports = airports;
          this.selectedAirport = airports[0];
        });
    });

    this.availableOptions = this.taxiConfigurationService.taxiAvailableOptionValues;

    this.paymentOption?.valueChanges.subscribe(_ => {
      this.handleDestinationOrPaymentUpdated();
    });

    this.numberOfCars?.valueChanges.subscribe(_ => {
      this.priceService.setNumberOfCars(this.numberOfCars?.value);
    })

    this.guestName?.valueChanges
      .pipe(
        debounceTime(1000)
      )
      .subscribe(value => {
        if (value === null || value.trim() === '')
          return;

        if (!isNaN(value) && value.trim() !== '') {
          this.roomNo?.setValue(value);
          this.roomNumberGetInfo();
        }
      });

    priceService.taxiPrice.subscribe(price => {
      var phoneNo = this.phoneNo;
      var guestName = this.guestName;
      this.taxiPrice?.setValue(0);
      if (price !== undefined) {
        this.taxiPrice?.setValue(price.customerPrice);
        phoneNo?.setValidators([tools.oneOfTwoFieldsRequired(guestName!)]);
        guestName?.setValidators([tools.oneOfTwoFieldsRequired(phoneNo!)]);
      }
      guestName?.updateValueAndValidity();
      phoneNo?.updateValueAndValidity();
    });

    this.commentToDriver!.setValue(hotelService.defaultCommentToTaxiDriverValue);
    this.defaultCommentToTaxiDriverSubscription = hotelService.defaultCommentToTaxiDriver.subscribe(comment => this.commentToDriver!.setValue(comment));

    this.lastActivityTime = Date.now();
    ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach(event => {
      window.addEventListener(event, () => this.updateLastActivity());
    });
  }

  ngOnInit(): void {
    this.taxiConfigurationService.taxiAvailableOptions.subscribe((data) => {
      this.availableOptions = data;
    });

    this.taxiConfigurationService.taxiNames.subscribe((data) => {
      this.taxiNames = data;
      this.taxiNames.forEach(taxiName => {
        this.isLogoAvailable[taxiName] = true;
      });
    });

    this.taxiConfigurationService.taxiName.subscribe((data) => {
      if (!this.selectedTaxiCompany) {
        this.selectedTaxiCompany = data;
        this.priceService.setChosenTaxiCompany(this.selectedTaxiCompany, false);
        this.cdr.detectChanges();
      }
    });

    this.availableOptions = this.taxiConfigurationService.taxiAvailableOptionValues;

    this.taxiNames = this.taxiConfigurationService.taxiNamesValues;
    this.taxiNames.forEach(taxiName => {
      this.isLogoAvailable[taxiName] = true;
    });

    if (!this.selectedTaxiCompany) {
      this.selectedTaxiCompany = this.taxiConfigurationService.taxiNameValue;
      this.priceService.setChosenTaxiCompany(this.selectedTaxiCompany, false);
      this.cdr.detectChanges();
    }

    this.destination?.valueChanges.subscribe(value => {
      if (!value?.address && value == this.translate.instant('NoDestination')) {
        value = {
          name: this.translate.instant('NoDestination'),
          address: "NoDestination"
        };
      }
      if (!value?.address) return;
      if (value?.address == "FromAirport") {
        this.showAirports = true;
        this.toHotelActive = false;
        this.priceService.setTo({ address: this.hotelInfo!.address, name: this.hotelInfo!.name }, false)
        this.from?.setValue({ address: this.airports![0].address, name: this.airports![0].name });
        this.commentToDriver?.setValue(this.translate.instant("airportComment"));
      }
      else if (value?.address == "FromHotelAddress") {
        if (this.showAirports) {
          this.resetAirport();
          this.showAirports = false;
        }
        if (!this.toHotelActive) {
          this.from?.setValue({ address: '', name: '' });
          this.priceService.setTo({ address: this.hotelInfo!.address, name: this.hotelInfo!.name }, false)
        }
        this.toHotelActive = true;
        this.commentToDriver?.setValue(this.translate.instant("toHotelDefaultComment"));
      }
      else {
        if (this.showAirports) {
          this.resetAirport();
          this.showAirports = false;
        }
        this.toHotelActive = false;
        this.priceService.setFrom({ address: this.hotelInfo!.address, name: this.hotelInfo!.name }, false)
        this.priceService.setTo(value);
      }

      if (value.address != "NoDestination") {
        this.noDestChecked = false;
      }

      if (value.address != "FromHotelAddress") {
        this.rideService.getFixedComment(value.address, this.paymentOption?.value).subscribe(
          (data: DriverComments) => {
            this.driverComments = data;
            if (!this.driverComments.prependComment) { this.commentToDriver!.setValue(this.driverComments.defaultComment) }
            else { this.commentToDriver!.setValue(this.driverComments.prependComment + ", " + this.driverComments.defaultComment) }
            if (this.driverComments.chooseACommentList) {
              const chooseWhichComment = this.dialog.open(ChooseWhichCommentComponent, {
                disableClose: true, data: {
                  chooseACommentList: this.driverComments.chooseACommentList
                }
              });

              chooseWhichComment.afterClosed().subscribe((result) => {
                this.commentToDriver!.setValue(result + ", " + this.driverComments!.defaultComment)
              });
            }
          }
        )
      }

      this.destination?.setValue(value, { onlySelf: true, emitEvent: false, emitModelToViewChange: true });
      this.handleDestinationOrPaymentUpdated();
    }, error => {
      this.log.error("Error on address", {
        errorObject: error,
        hotelId: this.hotelId,
        guestName: this.guestName?.value,
        location: this.destination
      });
    });

    this.from?.valueChanges.subscribe(value => {
      if (!value?.address) return;
      this.priceService.setFrom(value)
      this.from?.setValue(value, { onlySelf: true, emitEvent: false, emitModelToViewChange: true });
    }, error => {
      this.log.error("Error on address", {
        errorObject: error,
        hotelId: this.hotelId,
        guestName: this.guestName?.value,
        location: this.from
      });
    });

    this.secondDestination.get("largeTaxi")?.valueChanges.subscribe((value: boolean) => {
      if (this.showLargePhone() && value && !this.largeTaxiPopupOpen) {
        this.openPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue)
        this.largeTaxiPopupOpen = true;
      }
      else if (!value)
        this.largeTaxiPopupOpen = false;
      this.priceService.setIsLargeTaxi(value);
    });

    //Refresh otherdestinations on a random timer
    this.scheduleDailyTask();
  }

  ngOnDestroy(): void {
    this.defaultCommentToTaxiDriverSubscription?.unsubscribe();
    this.paymentOptionsSubscription?.unsubscribe();

    this.subscr$.next();
    this.subscr$.complete();
  }

  updateValidators() {
    if ((this.hasDestination() && (this.paymentOption!.value & PaymentOption.ApplyToBill) == PaymentOption.ApplyToBill) || (this.paymentOption!.value & PaymentOption.PayByTerminal) == PaymentOption.PayByTerminal) {
      if (this.phoneNo && this.guestName)
        this.guestName?.setValidators([this.tools.oneOfTwoFieldsRequired(this.phoneNo)])
      if ((this.paymentOption!.value & PaymentOption.ApplyToBill) == PaymentOption.ApplyToBill)
        this.roomNo?.setValidators(Validators.required)
    }
    else {
      this.roomNo?.clearValidators()
      this.guestName?.clearValidators()
      this.phoneNumberUpdateValidator(false);
    }

    if (this.destination!.value.address == "NoDestination") {
      if ((this.hotelInfo!.noDestinationSettings & NoDestinationSettings.WithNameAndPhone) == NoDestinationSettings.WithNameAndPhone) {
        this.phoneNumberUpdateValidator(true);
        this.guestName?.setValidators(Validators.required)
      }
      else {
        this.phoneNumberUpdateValidator(false);
        this.guestName?.clearValidators();
      }
    }

    if (this.destination!.value.address == "FromHotelAddress") {
      this.phoneNumberUpdateValidator(true);
      this.guestName?.setValidators(Validators.required)
    }

    if (this.showAirports && this.airports!.some(airports => airports.address === this.from?.value.address)) {
      this.guestName?.setValidators(Validators.required)
      this.phoneNumberUpdateValidator(true)
      this.flightNo?.setValidators(Validators.required);
    }
    else
      this.flightNo?.clearValidators();

    this.phoneNo!.updateValueAndValidity();
    this.guestName!.updateValueAndValidity();
    this.roomNo!.updateValueAndValidity();
    this.flightNo!.updateValueAndValidity();
  }

  updateHotelInfo() {
    this.priceService.setFromHotelAddress();

    if (!this.terminalReaderId)
      this.terminalReaderId = this.hotelInfo!.terminalReaderId;

    this.initTerminal();

    if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.HideNameAndPhone) === HotelSystemIntegration.HideNameAndPhone)
      this.hideNameAndPhone = true;

    if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill)
      this.hotelSystemIntegrationApplyToBill = true;

    if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.GetCustomer) === HotelSystemIntegration.GetCustomer)
      this.getCustomer = true;

    if ((this.hotelInfo!.hotelSettings & HotelSettings.HideEverything) === HotelSettings.HideEverything)
      this.hideEverything = true;

    if ((this.hotelInfo!.noDestinationSettings & NoDestinationSettings.NotAllowed) !== NoDestinationSettings.NotAllowed &&
      (this.hotelInfo!.noDestinationSettings & NoDestinationSettings.NoOrderNowButton) != NoDestinationSettings.NoOrderNowButton) {
      this.otherDestinations.push({ name: this.translate.instant('orderNowDestination'), address: "OrderNow" });
      this.getOtherAmount = this.getOtherAmount - 1;
      this.noDestinationAllowed = true;
    }

    if ((this.hotelInfo!.hotelSettings & HotelSettings.RemoveToHotelButton) !== HotelSettings.RemoveToHotelButton) {
      this.otherDestinations.push({ name: this.translate.instant('orderFromHotel'), address: "FromHotelAddress" });
      this.getOtherAmount = this.getOtherAmount - 1;
    }

    this.otherDestinations.push({ name: this.translate.instant('fromAirportToHotel'), address: "FromAirport" });
    this.boldCount = 9 - this.getOtherAmount;

    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      amount: this.getOtherAmount
    }

    this.getTaxiService.getOtherDestinations(body).subscribe((data) => {
      data.forEach((otherDest: IDestination) => {
        this.otherDestinations.push(otherDest);
      });
    });

    //Setting the default location to be Order now
    if ((this.hotelInfo!.noDestinationSettings & NoDestinationSettings.DefaultNoDest) == NoDestinationSettings.DefaultNoDest)
      this.setDefault();
  }

  isShowShare($event: any, largeTaxi?: boolean) {
    if ($event !== null && $event.checked && largeTaxi) {
      this.openPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
    }
    this.showOnLarge = this.secondDestination.value.largeTaxi;

    this.priceService.setIsLargeTaxi(this.secondDestination.value.largeTaxi);

    this.updateValidators();

    if (this.secondDestination.value.largeTaxi)
      this.stationCar?.disable();
    else
      this.stationCar?.enable();

    if (this.secondDestination.value.stationCar)
      this.largeTaxi?.disable();
    else
      this.largeTaxi?.enable();
  }

  hotelPaymentChanged(hotelPayment: boolean) {
    if (hotelPayment)
      this.paymentOption!.setValue(PaymentOption.HotelPays);
    else
      this.paymentOption!.setValue(PaymentOption.PayInTaxi);
  }

  hasDestination(): boolean {
    return !(this.destination!.value != null && this.destination!.value.address == "NoDestination");
  }

  increment(controlName: string): void {
    const currentValue = Number(this.secondDestination.get(controlName)?.value) || 0; // Convert to number
    if (currentValue < 25) { // Ensure max limit is respected
      this.secondDestination.get(controlName)?.setValue(currentValue + 1);
    }
  }

  decrement(controlName: string): void {
    const currentValue = Number(this.secondDestination.get(controlName)?.value) || 0; // Convert to number
    if (currentValue > 1) { // Ensure min limit is respected
      this.secondDestination.get(controlName)?.setValue(currentValue - 1);
    }
  }

  selectAirport(event: any) {
    this.selectedAirport = event.value;
    this.from?.setValue(event.value);
  }

  resetAirport() {
    this.from?.setValue({ name: this.hotelInfo?.name, address: this.hotelInfo?.address }, false);
    this.destination?.setValue("", false);
    this.showAirports = false;
  }

  onTaxiBoxClick(name: string): void {
    this.selectedTaxiCompany = name;
    this.taxiConfigurationService.updateSelectedTaxiCompany(name);
    this.availableOptions = this.taxiConfigurationService.taxiAvailableOptionValues;
    this.priceService.setChosenTaxiCompany(name, true);
  }

  noDestination(event: MatCheckboxChange): void {
    this.noDestChecked = event.checked;
    if (event.checked)
      this.destination?.setValue({ name: this.translate.instant('NoDestination'), address: "NoDestination" });
    else
      this.destination?.setValue({ name: '', address: '' }, false);
  }

  setDefault(): void {
    this.destination?.setValue({
      name: this.translate.instant('NoDestination'),
      address: "NoDestination"
    });
    this.noDestChecked = true;
  }

  showTaxiOptions(): void {
    this.showOption = !this.showOption;
  }

  updateLastActivity(): void {
    this.lastActivityTime = Date.now();
  }

  // Utility to get a random delay
  getRandomDelay(minMinutes: number, maxMinutes: number): number {
    const minDelay = minMinutes * 60 * 1000; // convert minutes to milliseconds
    const maxDelay = maxMinutes * 60 * 1000;
    return Math.random() * (maxDelay - minDelay) + minDelay;
  }

  // Schedule the next refresh
  scheduleDailyTask(): void {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(now.getDate() + 1);
    tomorrow.setHours(2, 0, 0, 0); // Start range: 2:00 

    // Random delay between 2:00 and 4:00 
    const randomDelay = Math.floor(Math.random() * (2 * 60 * 60 * 1000)); // Up to 2 hours
    const scheduledTime = new Date(tomorrow.getTime() + randomDelay);

    const delayUntilExecution = scheduledTime.getTime() - now.getTime();

    setTimeout(() => {
      this.startChecking();
    }, delayUntilExecution);
  }

  private startChecking(): void {
    const interval = setInterval(() => {
      if (!this.isUserActive()) {
        this.refreshDestinations();
        clearInterval(interval);
        this.scheduleDailyTask();
      }
    }, 1 * 60 * 1000); //Every minute
  }

  refreshDestinations(): void {
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      amount: this.getOtherAmount
    };

    this.getTaxiService.getOtherDestinations(body).subscribe((data) => {
      // Adjust the existing array to ensure it doesn't exceed getOtherAmount
      if (this.otherDestinations.length + data.length > this.getOtherAmount) {
        // Calculate the number of extra items that can be kept
        const maxExistingItems = this.otherDestinations.length - this.getOtherAmount;
        this.otherDestinations = this.otherDestinations.slice(0, maxExistingItems);
      }

      // Add new destinations up to the limit
      data.forEach((otherDest: IDestination) => {
        if (this.otherDestinations.length < 9) {
          this.otherDestinations.push(otherDest);
        }
      });
    });
  }

  phoneNumberUpdateValidator(set: boolean): void {
    if (set) {
      this.phoneNo?.setValidators([
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(/^((00|\+)\d{8,16})$/)
      ]);
    }
    else
      this.phoneNo?.clearValidators();

    this.phoneNo?.updateValueAndValidity();
  }

  hasMultipleTaxiCompanies(): boolean {
    if (this.taxiNames && this.taxiNames.length > 1)
      return true;
    else
      return false;
  }

  paymentOptionFilter(option: PaymentOption) {
    if ((option & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal) {
      return PaymentOption.PayByTerminal
    }
    return option;
  };

  paymentOptionFilterIntegration(option: PaymentOption) {
    if ((option & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill) {
      return PaymentOption.ApplyToBill
    }
    return option;
  };

  getSearchEngine(): SearchEngine {
    return this.taxiConfigurationService.searchEngineValue;
  }

  getHideEverythingExpand(): boolean {
    if (!this.hotelInfo?.hotelSettings)
      return false;
    if ((this.hotelInfo!.hotelSettings & HotelSettings.HideEverything) === HotelSettings.HideEverything)
      return this.hideEverything
    else return false;
  }

  getHideEverythingCollapse(): boolean {
    if (!this.hotelInfo?.hotelSettings)
      return false;
    if ((this.hotelInfo!.hotelSettings & HotelSettings.HideEverything) === HotelSettings.HideEverything)
      return !this.hideEverything;
    else return false;
  }

  hideEverythingClick() {
    this.hideEverything = !this.hideEverything;
  }

  getSpecialComment(): void {
    this.getHotelSystem.getSpecialHotelInformation(this.pickupTime!.value.toISOString())
      .subscribe(
        data => {
          if (data && data.length > 0) {
            const specialInformation = this.dialog.open(SpecialHotelInformationPopup, { data })
            specialInformation.afterClosed().subscribe(_ => this.order())
          }
          else
            this.order();
        },
        error => {
          this.order()
        }
      );
  }

  public initTerminal(): void {
    if (this.hotelId && this.hotelInfo?.terminalLocationId && this.terminalReaderId) {
      this.paymentApiService
        .setupTerminal(this.hotelId, this.hotelInfo?.terminalLocationId, this.terminalReaderId);
    }
  }

  phoneSelect(event: any): void {
    this.phoneNo?.setValue(event?.tel);
  }

  handleDestinationOrPaymentUpdated() {
    if (this.destination!.value?.address == "OrderNow") {
      this.presentLoading();
      this.saveDirectTaxi();
    }

    if (this.destination!.value?.address == "NoDestination") {
      if ((this.hotelService.paymentOptionsValue & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi) {
        if ((this.paymentOption!.value & PaymentOption.PayInTaxi) != PaymentOption.PayInTaxi) {
          this.paymentOption?.setValue(PaymentOption.PayInTaxi);
        }
        this.noDestinationSelected = true;
      }

      this.hotelService.allPaymentMethodShouldDisabled.emit(true);
    }
    else {
      this.noDestinationSelected = false;
      this.hotelService.allPaymentMethodShouldDisabled.emit(false);
      if ((this.hotelService.paymentOptionsValue & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi
        && (this.hotelService.paymentOptionsValue & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill
        && (this.hotelService.paymentOptionsValue & PaymentOption.PayByTerminal) != PaymentOption.PayByTerminal
        && (this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill) {
        if ((this.paymentOption!.value & PaymentOption.ApplyToBill) != PaymentOption.ApplyToBill)
          this.paymentOption?.setValue(PaymentOption.PayInTaxi);
      }

      else if ((this.hotelService.paymentOptionsValue & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal) {
        this.paymentOption?.setValue(PaymentOption.PayByTerminal);
      }
    }

    this.canOrderLargeTaxi = true;
    if (this.priceService.getPaymentOption != this.paymentOption?.value)
      this.priceService.setPaymentOption(this.paymentOption?.value, true);

    this.updateValidators();
  }

  public displayOption(optionName: string): boolean {
    return this.availableOptions === null || this.availableOptions.some(option => option.toUpperCase() === optionName.toUpperCase());
  }

  private showLargeLoading(): boolean {
    return this.availableOptions.some(option => option.toUpperCase() === 'LOADLARGE');
  }

  private showLargePhone(): boolean {
    return this.availableOptions.some(option => option.toUpperCase() === 'LARGEPHONE');
  }

  isUserActive(): boolean {
    // Consider user active if last activity was within the last 5 minutes ***
    return (Date.now() - this.lastActivityTime) < 5 * 60 * 1000;
  }

  hotelSystemIntegrationCheck() {
    if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill)
      this.showApplyToBill = true;
    else
      this.showApplyToBill = false;

    if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.AutoApplyToBill) === HotelSystemIntegration.AutoApplyToBill
      && !this.noDestinationSelected) {
      this.paymentOption?.setValue(PaymentOption.ApplyToBill)
    }
  };

  getTaxiAttributes(): AttributesToTaxi {
    let attributes = AttributesToTaxi.None;

    if (this.animal?.value === true) {
      attributes |= AttributesToTaxi.Animal;
    }
    if (this.bike?.value === true) {
      attributes |= AttributesToTaxi.Bike;
    }
    if (this.electricCar?.value === true) {
      attributes |= AttributesToTaxi.ElectricCar;
    }
    if (this.carseat?.value === true) {
      attributes |= AttributesToTaxi.Carseat;
    }
    if (this.oneBoosterSeat?.value === true) {
      attributes |= AttributesToTaxi.OneBoosterSeat;
    }
    if (this.twoBoosterSeats?.value === true) {
      attributes |= AttributesToTaxi.TwoBoosterSeats;
    }
    if (this.childSeat?.value === true) {
      attributes |= AttributesToTaxi.ChildSeat;
    }
    if (this.stationCar?.value === true) {
      attributes |= AttributesToTaxi.EstateCar;
    }
    if (this.hybridCar?.value === true) {
      attributes |= AttributesToTaxi.HybridCar;
    }

    return attributes;
  }

  public onChangeDate(): void {
    this.priceService.setSelectedDate(this.pickupTime?.value);
  }

  getJsonToken(): void {
    //Somehow they are managing to order without jsontoken, so this is to fix that.
    if (!this.priceService.jsonWebToken) {
      this.priceService.fetchPriceInfo();
    }
  }

  public openPopUp(taxiName: string, taxiHotelNumber: string): void {
    this.dialog.open(LargeTaxiPopupComponent, {
      data: {
        taxiName: taxiName,
        taxiHotelNumber: taxiHotelNumber
      }
    });
  }

  public roomNumberGetInfo() {
    if (this.roomNo?.value && this.getCustomer) {
      this.getHotelSystem.getCustomerInfo(this.roomNo?.value).subscribe((data: HotelSystemCustomer) => {
        if (data && (data.phoneNumber || data.guestName))
          this.hotelSystemIntegrationCheck();
        else
          this.showApplyToBill = false;

        if (data && data.phoneNumber)
          this.phoneNumber = { phoneNumber: data.phoneNumber, countryCode: data.countryCodeInt };

        if (data && data.guestName)
          this.guestName?.setValue(this.roomNo?.value + this.spacing + data.guestName)

        if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.HideNameAndPhone) === HotelSystemIntegration.HideNameAndPhone)
          this.hideNameAndPhone = false;

        if (data == null)
          this.dialog.open(RoomNumberIncorrect);
      }, async (error) => {
        if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.HideNameAndPhone) === HotelSystemIntegration.HideNameAndPhone)
          this.hideNameAndPhone = false;

        await this.hideLoading();
        if (error.status == 400) {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
            cssClass: 'alertmessage3',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
        else {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant('500errorMsg'),
            cssClass: 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
      });
    }
  }

  public disableOptions(): boolean {
    if (this.hideEverything)
      return true;
    else
      return this.availableOptions?.some(x => x.toUpperCase() === 'DISABLE');
  }

  async submit() {
    if (this.largeTaxi?.value && this.showLargePhone()) {
      this.openPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue)
      return
    }

    if (this.pickupTime?.value)
      this.getSpecialComment()
    else
      this.order();
  }

  async order() {
    this.log.information("Ordering taxi with hotelId: " + this.hotelId);
    await this.presentLoading();

    if (this.roomNo?.value !== null) {
      const prefix = this.roomNo?.value + this.spacing;
      const guestName = this.guestName?.value;
      if (guestName !== null) {
        this.guestName?.setValue(guestName.startsWith(prefix) ? guestName.slice(prefix.length) : guestName);
      }
    }

    //If nothing is in destination, asume a nodest tour.
    if (!this.destination?.value) {
      this.setDefault();
    }

    this.formSubmit = true;
    this.updateValidators();

    this.phoneNo?.updateValueAndValidity();
    this.roomNo?.updateValueAndValidity();
    this.guestName?.updateValueAndValidity();
    this.paymentOption!.updateValueAndValidity();
    this.destination?.updateValueAndValidity();
    this.flightNo?.updateValueAndValidity();

    if (!this.secondDestination.valid) {
      this.validated = false;
      await this.hideLoading();
      logInvalidControls(this.secondDestination)
      return;
    }
    else
      this.validated = true;

    if (!this.receptionistInitialsService.isConfigurationValid()) {
      await this.hideLoading();
      return;
    }

    if ((this.paymentOption!.value & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill && !this.roomNo?.value) {
      await this.hideLoading();
      return;
    }

    if (this.destination!.value != null && this.destination!.value.address == "NoDestination") {
      this.saveDirectTaxi();
      return;
    }
    else if (this.showAirports && this.airports!.some(airports => airports.address === this.from?.value.address)) {
      this.getJsonToken();
      this.saveFromAirport();
      return;
    }
    else {
      this.getJsonToken();
    }

    var destination = this.secondDestination.value.destination;
    var from: IDestination | undefined;
    if (this.toHotelActive || destination?.address == "FromHotelAddress") {
      from = this.secondDestination.value.from;
      destination = { name: this.hotelInfo!.name, address: this.hotelInfo!.address };
    }

    const body: ISaveOtherDestinationRequest = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      from: from,
      location: destination,
      roomNumber: this.secondDestination.value.roomNo,
      receptionistInitials: this.receptionistInitialsService.getInitials(),
      guestName: this.secondDestination.value.guestName,
      phoneNumber: this.secondDestination.value.phoneNo,
      largeTaxi: this.secondDestination.value.largeTaxi,
      pickupTime: this.pickupTime!.value.toISOString(),
      numberOfCars: Number(this.numberOfCars?.value),
      numberOfGuests: this.secondDestination.value.largeTaxi ? this.secondDestination.value.numberOfGuests : undefined,
      stationCar: this.secondDestination.value.stationCar,
      attributesToTaxi: this.getTaxiAttributes(),
      comment: this.commentToDriver?.value,
      orderType: OrderType.Hotel,
      paymentOption: this.paymentOption!.value ?? PaymentOption.ApplyToBill,
      rideType: this.priceService.rideType.value,
      jsonWebToken: this.priceService.jsonWebToken,
      paymentIntentId: this.paymentIntentId,
      chosenTaxiCompany: this.selectedTaxiCompany,
    }

    if ((this.paymentOption!.value & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill && this.roomNo?.value && (this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill) {
      this.saveApplyToBill();
      return;
    }
    else if ((this.paymentOption!.value & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal) {
      const saveOtherDestinationTerminalRequest: ISaveOtherDestinationTerminalRequest = {
        hotelId: body.hotelId,
        accesstoken: body.accessToken,
        from: body.from,
        location: body.location,
        roomNumber: body.roomNumber,
        guestName: body.guestName,
        phoneNumber: body.phoneNumber,
        pickupTime: body.pickupTime,
        rideType: RideType.FixedPrice,
        orderType: OrderType.Hotel,
        paymentOption: body.paymentOption,
        jsonWebToken: this.priceService.jsonWebToken!,
        largeTaxi: body.largeTaxi,
        numberOfGuests: body.numberOfGuests,
        numberOfCars: body.numberOfCars,
        stationCar: body.stationCar,
        attributesToTaxi: this.getTaxiAttributes(),
        chosenTaxiCompany: this.selectedTaxiCompany,
      }
      this.goToTerminal(saveOtherDestinationTerminalRequest);
      return;
    }

    this.goSaveOtherDestinations(body);
  }

  goToTerminal(body: ISaveOtherDestinationTerminalRequest) {
    const createIntentRequest: ICreatePaymentIntentRequest = {
      hotelId: this.hotelId,
      guestName: this.guestName?.value,
      terminalReaderId: this.terminalReaderId,
      jsonWebToken: body.jsonWebToken
    }
    this.log.information("goToTerminal", JSON.stringify({ body: body, createIntentRequest: createIntentRequest }));

    this.paymentApiService.createIntent(createIntentRequest).subscribe(async (data: IPaymentIntentResponse) => {
      this.log.information("createIntentResponse", JSON.stringify(data));

      if (data.message) {
        this.hideLoading();
        const errorAlert = await this.alertController.create({
          header: "No success",
          subHeader: data.message,
          cssClass: 'alertmessage',
          buttons: [
            {
              text: "Okay",
              handler: () => {

              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        this.paymentIntentId = data.paymentIntentId; //Save the payment intent id if eventually not ordering from terminal

        //Wait payment
        const collectPaymentPopupRef = this.dialog.open(CollectPaymentPopupComponent, { disableClose: true });
        await this.hideLoading();

        await this.paymentApiService.collectPayment(data).then(async (collectPaymentResult: CollectPaymentMethodResponse) => {
          collectPaymentPopupRef.close();

          await this.presentLoading();

          body.paymentIntentId = collectPaymentResult.paymentIntent.id;

          this.saveTaxiService.saveOtherDestinationTerminal(body).subscribe(async (data) => {
            await this.hideLoading();
            this.messageShow = true;
            this.taxiBookingId = data;
            if (body.largeTaxi) {
              this.showLargeTaxiLoading = true;
              this.timeOutTimer = 40000;
              this.startProgessTimer(30, this.taxiBookingId);
            }
            else {
              this.timeOutTimer = 0;
              this.showLargeTaxiLoading = false;
            }
            setTimeout(async () => {
              const dialogRef = this.dialog.open(PaidPopupComponent);
              dialogRef.afterClosed().subscribe(result => {
                this.resetInterface();
              });
            }, this.timeOutTimer);
          }, async (error) => {
            await this.hideLoading();
            this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
          });
        }).catch(async (error: any) => {
          this.loading.dismiss();
        });
      }
    }, async (error) => {
      this.log.error("Error when invoking createIntent method", {
        errorObject: error,
        price: this.secondDestination.value.taxiPrice,
        hotelId: this.hotelId,
        guestName: this.guestName?.value,
        terminalReaderId: this.terminalReaderId,
        paymentInfo: body
      });

      this.loading.dismiss();
      this.openErrorPopUp(this.taxiConfigurationService.taxiNameValue, this.taxiConfigurationService.taxiHotelNumberValue);
    });
  }

  public saveApplyToBill(): void {
    var from: IDestination | undefined;
    var destination = this.secondDestination.value.destination;
    if (this.toHotelActive || destination?.address == "FromHotelAddress") {
      from = this.secondDestination.value.from;
      destination = { name: this.hotelInfo!.name, address: this.hotelInfo!.address };
    }

    let body: ISaveOtherDestinationTerminalRequest = {
      hotelId: this.hotelId,
      accesstoken: this.accessToken,
      from: from,
      location: destination,
      roomNumber: this.secondDestination.value.roomNo,
      receptionistInitials: this.receptionistInitialsService.getInitials(),
      guestName: this.secondDestination.value.guestName,
      phoneNumber: this.secondDestination.value.phoneNo,
      largeTaxi: this.secondDestination.value.largeTaxi,
      pickupTime: this.pickupTime!.value.toISOString(),
      numberOfCars: Number(this.numberOfCars?.value),
      numberOfGuests: this.secondDestination.value.largeTaxi ? this.secondDestination.value.numberOfGuests : undefined,
      stationCar: this.secondDestination.value.stationCar,
      comment: this.commentToDriver?.value,
      orderType: OrderType.Hotel,
      paymentOption: PaymentOption.ApplyToBill,
      rideType: RideType.FixedPrice,
      jsonWebToken: this.priceService.jsonWebToken!,
      paymentIntentId: this.paymentIntentId,
      attributesToTaxi: this.getTaxiAttributes(),
      chosenTaxiCompany: this.selectedTaxiCompany,
    }
    this.saveTaxiService.saveOtherDestinationTerminal(body).subscribe(async (data) => {
      await this.hideLoading();
      this.taxiBookingId = data;
      this.messageShow = true;
      if (body.paymentOption != PaymentOption.PayInTaxi)
        this.showEmote = true;
      if (body.largeTaxi && this.showLargeLoading()) {
        this.showLargeTaxiLoading = true;
        this.timeOutTimer = 40000;
        this.startProgessTimer(30, this.taxiBookingId);
      }
      else {
        this.timeOutTimer = 5000;
        this.showLargeTaxiLoading = false;
      }
      setTimeout(async () => {
        this.resetInterface();
      }, this.timeOutTimer);
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else if (error.status == 406) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`taxiCompanyResponse`).replace('{responseMessage}', error.error).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    })
  }

  async saveDirectTaxi() {
    const body: IDirectTaxiRequest = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      roomNumber: this.secondDestination.value.roomNo,
      guestName: this.secondDestination.value.guestName,
      numberOfCars: this.numberOfCars?.value,
      numberOfGuests: this.numberOfGuests?.value,
      receptionistInitials: this.receptionistInitialsService.getInitials(),
      phoneNumber: this.secondDestination.value.phoneNo,
      pickupTime: this.pickupTime!.value.toISOString(),
      stationCar: this.secondDestination.value.stationCar,
      comment: this.commentToDriver?.value,
      paymentOption: PaymentOption.PayInTaxi,
      largeTaxi: this.secondDestination.value.largeTaxi,
      orderType: OrderType.Hotel,
      attributesToTaxi: this.getTaxiAttributes(),
      chosenTaxiCompany: this.selectedTaxiCompany,
    }
    this.saveTaxiService.directTaxiRide(body).subscribe(async (data) => {
      await this.hideLoading();
      this.taxiBookingId = data;
      this.messageShow = true;
      if (body.paymentOption != PaymentOption.PayInTaxi)
        this.showEmote = true;
      if (body.largeTaxi && this.showLargeLoading()) {
        this.showLargeTaxiLoading = true;
        this.timeOutTimer = 40000;
        this.startProgessTimer(30, this.taxiBookingId);
      }
      else {
        this.timeOutTimer = 5000;
        this.showLargeTaxiLoading = false;
      }
      setTimeout(async () => {
        this.resetInterface();
      }, this.timeOutTimer);
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else if (error.status == 406) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`taxiCompanyResponse`).replace('{responseMessage}', error.error).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });
  }

  goSaveOtherDestinations(body: ISaveOtherDestinationRequest) {
    this.saveTaxiService.saveOtherDestinations(body).subscribe(async (data) => {
      await this.hideLoading();
      this.taxiBookingId = data;
      this.receiptService.update();
      this.messageShow = true;
      if (body.paymentOption != PaymentOption.PayInTaxi)
        this.showEmote = true;
      if (body.largeTaxi && this.showLargeLoading()) {
        this.showLargeTaxiLoading = true;
        this.timeOutTimer = 40000;
        this.startProgessTimer(30, this.taxiBookingId);
      }
      else {
        this.timeOutTimer = 5000;
        this.showLargeTaxiLoading = false;
      }
      setTimeout(async () => {
        this.resetInterface();
      }, this.timeOutTimer);
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else if (error.status == 406) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`taxiCompanyResponse`).replace('{responseMessage}', error.error).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    })
  }

  async saveFromAirport() {
    let airport = this.airports!.find(airports => airports.address === this.from?.value.address)

    const body: IFromAirportRideRequest = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      airportId: airport!.id,
      phoneNumber: this.phoneNo?.value,
      flightNumber: this.flightNo?.value,
      shareRide: false,
      comment: this.commentToDriver?.value,
      pickupTimeSetManually: true,
      numberOfCars: this.numberOfCars?.value,
      largeTaxi: this.largeTaxi?.value,
      guestName: this.guestName?.value,
      receptionistInitials: this.receptionistInitials?.value,
      numberOfGuests: this.numberOfGuests?.value,
      flightNotFound: undefined,
      stationCar: this.stationCar?.value,
      attributesToTaxi: this.getTaxiAttributes(),
      email: '', //this.email.value, // Is this needed?
      paymentOption: this.paymentOption!.value ?? PaymentOption.ApplyToBill,
      flightDate: this.pickupTime!.value,
      roomNumber: this.roomNo?.value,
      chosenTaxiCompany: this.selectedTaxiCompany,
      rideType: RideType.FromAirport,
      orderType: OrderType.Hotel
    }
    this.saveTaxiService.saveTaxiToHotel(body).subscribe(async (data) => {
      await this.hideLoading();
      this.taxiBookingId = data;
      this.messageShow = true;
      if (body.paymentOption != PaymentOption.PayInTaxi)
        this.showEmote = true;
      if (body.largeTaxi && this.showLargeLoading()) {
        this.showLargeTaxiLoading = true;
        this.timeOutTimer = 40000;
        this.startProgessTimer(30, this.taxiBookingId);
      }
      else {
        this.timeOutTimer = 5000;
        this.showLargeTaxiLoading = false;
      }
      setTimeout(async () => {
        this.resetInterface();
      }, this.timeOutTimer);
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else if (error.status == 406) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`taxiCompanyResponse`).replace('{responseMessage}', error.error).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });
  }

  scrollToBottom() {
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 500);
  }

  resetInterface(): void {
    //window.location.reload();
    this.priceService.stopFetching = true;

    this.secondDestination.reset({
      destination: { name: '', address: '' },
      commentToDriver: this.hotelService.defaultCommentToTaxiDriverValue,
      phoneNumber: { phoneNumber: '', countryCode: '' },
      pickupTime: new Date(),
      largeTaxi: false,
      numberOfCars: 1,
      stationCar: false,
      animal: false,
      bike: false,
      electricCar: false,
      numberOfGuests: 2,
      paymentOption: PaymentOption.PayInTaxi,
      taxiPrice: 0,
      carseat: false,
      oneBoosterSeat: false,
      twoBoosterSeats: false,
      childSeat: false,
      hybridCar: false,
    })

    this.priceService.stopFetching = false;

    //Setting the default location to be Order now
    if ((this.hotelInfo!.noDestinationSettings & NoDestinationSettings.DefaultNoDest) == NoDestinationSettings.DefaultNoDest)
      this.setDefault();
    else {
      this.noDestChecked = false;
      this.destination?.setValue(null)
    }

    this.messageShow = false;
    this.showLargeTaxiLoading = false;
  }

  public openErrorPopUp(taxiName: string, taxiHotelNumber: string): void {
    const dialogRef = this.dialog.open(ErrorOrderPopupComponent, {
      data: {
        taxiName,
        taxiHotelNumber
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetInterface();
      }
    });
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async startProgessTimer(seconds: number, Id: string) {
    const timer = interval(1000);

    const sub = timer.subscribe((sec) => {
      this.progressValue = (sec / seconds) * 100;

      if (sec === seconds - 5) {
        this.rideService.getLargeTaxiStatus(Id).subscribe(data => {
          this.largeTaxiCarResponse = this.translate.instant(`largeTaxiFound`).replace('{largeTaxiCarNo}', data.bookingId);
        }, (error => {
          this.showEmote = false;
          this.largeTaxiCarResponse = this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue)
        }))
      }
      if (sec === seconds) {
        sub.unsubscribe();
        this.progressValue = 0;
        this.loadingDone = true;
      }
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    if (this.loading) // Ensure loading is defined
      await this.loading.dismiss();
  }

  get receptionistInitials() {
    return this.secondDestination.get("receptionistInitials");
  }

  get guestNameValid() {
    return this.guestName?.valid;
  }

  get commentToDriver() {
    return this.secondDestination.get('commentToDriver');
  }

  get numberOfCars() {
    return this.secondDestination.get('numberOfCars');
  }

  get roomNo() {
    return this.secondDestination.get('roomNo');
  }

  get animal() {
    return this.secondDestination.get('animal');
  }

  get bike() {
    return this.secondDestination.get('bike');
  }

  get electricCar() {
    return this.secondDestination.get('electricCar');
  }

  get carseat() {
    return this.secondDestination.get('carseat');
  }

  get oneBoosterSeat() {
    return this.secondDestination.get('oneBoosterSeat');
  }

  get twoBoosterSeats() {
    return this.secondDestination.get('twoBoosterSeats');
  }

  get childSeat() {
    return this.secondDestination.get('childSeat');
  }

  get guestName() {
    return this.secondDestination.get("guestName");
  }

  get phoneNo() {
    return this.secondDestination.get('phoneNo');
  }

  get destination() {
    return this.secondDestination.get('destination');
  }

  get from() {
    return this.secondDestination.get('from');
  }

  get pickupTime() {
    return this.secondDestination.get('pickupTime');
  }

  get largeTaxi() {
    return this.secondDestination.get('largeTaxi');
  }

  get numberOfGuests() {
    return this.secondDestination.get('numberOfGuests');
  }

  get stationCar() {
    return this.secondDestination.get('stationCar');
  }

  get hybridCar() {
    return this.secondDestination.get('hybridCar');
  }

  get paymentOption() {
    return this.secondDestination.get('paymentOption');
  }

  get taxiPrice() {
    return this.secondDestination.get('taxiPrice');
  }

  get flightNo() {
    return this.secondDestination.get('flightNo');
  }

  get largeTaxiTranslation() {
    if (this.hotelInfo?.country === 'Norway') {
      const now = new Date();
      const day = now.getUTCDay(); // 0 (Sunday) to 6 (Saturday)
      const hour = now.getUTCHours(); // Hours in UTC

      // Check if it's between Friday 15:00 and Monday 08:00 UTC
      if (
        (day === 5 && hour >= 15) || // Friday after 15:00
        (day === 6) || // Saturday
        (day === 0) || // Sunday
        (day === 1 && hour < 8) // Monday before 08:00
      ) {
        return this.translate.instant('largeTaxiDescription-norway');
      }
    }
    return this.translate.instant('fixedDestination-largeTaxiDescription');
  }

  get showingCustomerPrice() {
    return this.priceService.isFixedDestination || this.priceService.showPrice;
  }
}
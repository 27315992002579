import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export interface InitPopupQRRestaurantComponentArgs {
  logoSrc?: string;
}

@Component({
  selector: 'app-init-popup-qr-restaurant',
  templateUrl: './init-popup-qr-restaurant.component.html',
  styleUrls: ['./init-popup-qr-restaurant.component.scss']
})

export class InitPopupQRRestaurantComponent {
  private _logoSrc: string = 'assets/images/logo_white.svg';

  set logoSrc(value: string | undefined) {
    if (value) this._logoSrc = value;
  }

  get logoSrc(): string | undefined {
    return this._logoSrc;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: InitPopupQRRestaurantComponentArgs, public dialogRef: MatDialogRef<InitPopupQRRestaurantComponentArgs>, private translate: TranslateService) {
    this.logoSrc = data.logoSrc;
  }

  continue(value: number): void {
    this.dialogRef.close(value);
  }

  updateComponentData(logoSrc: string | undefined) {
    this.logoSrc = logoSrc;
  }
}

import { AbstractControl } from '@angular/forms';

export class AddressValidator {

    public static detailedAddressValidator(formControl: AbstractControl): any {
        // Check if the control has a parent
        if (!formControl.parent) {
            return null;
        }

        // Safely check if value exists before accessing detailedAddress
        const value = formControl.value;
        const detailedAddress = value?.detailedAddress;

        // Check if detailedAddress exists and validate zipCode
        if (detailedAddress && !detailedAddress.zipCode) {
            return { invalidAddress: { value: formControl.value } };
        }

        return null;
    }
}

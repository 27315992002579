<ion-content id="bookTaxi" class="new">
  <app-header [logoSrc]="logoSrc" [name]="showName"></app-header>
  <div class="taxiHotel">
    <div class="top-line">
      <div class="destination">
        {{ "anotherDestination" | translate }}
      </div>
      <div class="dropdown">
        <button class="dropbtn" (click)="toggleDropdown()">
          {{ "taxi-rides" | translate }} & {{ "taxi-arrivals" | translate }} {{ getHasRides() }}
        </button>
        <div class="dropdown-content" [ngClass]="{'hide': !dropDownOpen}">
          <app-upcoming-rides (newRideDetected)="showDropdown($event)" 
            (rideSelected)="onRideSelected($event)"></app-upcoming-rides>
        </div>
      </div>    
    </div>
    <div class="center">
      <div class="left-container">
        <form class="orderForm">
            <app-reception-platform></app-reception-platform>
        </form>
      </div>      
      <div class="right-container">
        <div class="google-map">
          <app-google-map height="85vh" width="47vw" [carCoordinates]="taxiCars" [markerId]="markerId"></app-google-map>
        </div>
      </div>
    </div>
  </div>
</ion-content>
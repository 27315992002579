<div class="gfg">
    <app-header [logoSrc]="logoSrc" [gdpr]="true"></app-header>

    <div class="content">
        <div class="top-txt-box margin-bottom">
            <img id="picture" class="margin-bottom" src="/assets/images/taxi-ordering.png">
            <div class="top-txt"> {{ 'mobile_standard_toptext' | translate }}</div>
            <div class="top-txt"> {{ 'mobile_standard_secondtext' | translate }}</div>
        </div>    
    
        <div class="button-box">
            <div class="button-row">
                <button mat-flat-button class="button margin" (click)="continue(1)" color="secondary">
                    {{ 'selfServiceStation_orderTaxiNow' | translate }}
                </button>
                <button mat-flat-button class="button margin" (click)="continue(0)" color="secondary">
                    {{ 'selfServiceStation_orderTaxiFixedPrice' | translate }}
                </button>
            </div>
            <div class="button-row">
                <button mat-flat-button class="button margin" (click)="continue(2)" color="newprimary">
                    {{ 'selfServiceStation_orderTaxiAirport' | translate }}
                </button>
                <button *ngIf="showLocation()" mat-flat-button class="button margin" (click)="continue(3)" color="newprimary">
                    {{ getLocationTranslation(0) }}
                </button>
            </div>
        </div>
    </div>

    <app-bottom-banner class="bottom" [logoSrc]="logoSrc"></app-bottom-banner>
</div>
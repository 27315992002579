import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IDestination } from '../../models/destination.interface';

export interface InitPopupQRComponentArgs {
  logoSrc?: string;
  locations?: IDestination[] | undefined;
  hotelLogoSrc?: string;
  hotelName: string;
}

@Component({
  selector: 'app-init-popup-qr',
  templateUrl: './init-popup-qr.component.html',
  styleUrls: ['./init-popup-qr.component.scss']
})

export class InitPopupQRComponent {
  private _logoSrc: string = 'assets/images/logo_white.svg';
  private _hotelLogoSrc: string | undefined = undefined;

  hotelName: string;
  locations: IDestination[] | undefined = undefined;

  set logoSrc(value: string | undefined) {
    if (value) this._logoSrc = value;
  }

  get logoSrc(): string | undefined {
    return this._logoSrc;
  }

  set hotelLogoSrc(value: string | undefined) {
    if (value) this._hotelLogoSrc = value;
  }

  get hotelLogoSrc(): string | undefined {
    return this._hotelLogoSrc;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: InitPopupQRComponentArgs, public dialogRef: MatDialogRef<InitPopupQRComponent>, private translate: TranslateService) {
    this.hotelName = data.hotelName;
    this.logoSrc = data.logoSrc;
    this.hotelLogoSrc = data.hotelLogoSrc;
    this.locations = data.locations
  }

  continue(value: number): void {
    this.dialogRef.close(value);
  }

  getLocationTranslation(number: number): string {
    return this.translate.instant('selfServiceStation_orderTaxiSpecial').replace('{nameOfLocation}', this.locations?.[number].name?.replace("*", ""))
  }

  getTopText(): string {
    return this.translate.instant('selfServiceStation_mobile_topText').replace('{hotelName}', this.hotelName ?? "Hotel")
  }

  showLocationOrAiport(): boolean {
    if (this.locations?.length == 1)
      return true;
    else return false;
  }

  updateComponentData(logoSrc: string | undefined, hotelLogoSrc: string | undefined, hotelName: string, specialLocations: IDestination[]) {
    this.logoSrc = logoSrc;
    this.hotelLogoSrc = hotelLogoSrc;
    this.hotelName = hotelName;
    this.locations = specialLocations;
  }
}
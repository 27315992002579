import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HotelService } from 'src/app/services/hotel.service';
import { ICoordinate } from 'src/app/models/coordinate.interface';

@Component({
    selector: 'app-google-map',
    templateUrl: './google-map.component.html',
    styleUrls: ['./google-map.component.scss']
})
export class GoogleMapComponent implements OnInit, OnChanges {
    @Input() carCoordinates: ICoordinate[] = [];
    @Input() markerPosition: ICoordinate | undefined;
    @Input() width: string = '500px';
    @Input() height: string = '445px';
    @Input() markerId: string = '';

    markers: any;
    center: any;
    zoom = 12;
    mapOptions: any;
    fromLocation: any;

    constructor(private translate: TranslateService, private hotelService: HotelService) {
        this.markers = [];

        this.mapOptions = { maxZoom: 15, styles: this.mapStyles };
    }

    ngOnInit(): void {
        this.updatePosition();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updatePosition();

        this.markers = [];
        this.markers.push({ position: this.fromLocation });
        if (this.carCoordinates) {
            this.carCoordinates.forEach(c => {
                const additionalInfo = c.additionalGuestInfo ? `(${c.additionalGuestInfo}) ` : '';
                this.markers.push({
                    position: {
                        lat: c.lat,
                        lng: c.lng,
                    },
                    options: {
                        icon: {
                            url: '../../../assets/images/taxi-soft.svg',
                            scaledSize: new google.maps.Size(65, 60), // Adjust width and height (in pixels)
                        },
                        label: {
                            color: this.markerId === c.id ? '#ff0000' : '#ffffff',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            text: additionalInfo + c.minutesAway + this.translate.instant('googleMapTaxiText'),
                        }
                    },
                });
            });
        }
    }

    updatePosition() {
        this.getPosition().then(pos => {
            if (pos.lat === 0)
                return;

            this.fromLocation = { lat: pos.latitude, lng: pos.longitude };
            this.markers.push({ position: this.fromLocation });
            this.center = {
                lat: pos.latitude,
                lng: pos.longitude,
            };
        });
    }

    getPosition(): Promise<any> {
        var posistionResolve = { longitude: this.hotelService.longitude.value, latitude: this.hotelService.latitude.value };

        if (this.markerPosition !== undefined)
            posistionResolve = { longitude: this.markerPosition.lng, latitude: this.markerPosition.lat };

        return new Promise((resolve, reject) => {
            resolve(posistionResolve);
        });
    }

    mapStyles: google.maps.MapTypeStyle[] = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#1d2c4d"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#202020"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative.country",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "administrative.province",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#202020"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "landscape.natural",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#023e58"
                }
            ]
        },
        {
            "featureType": "poi",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#283d6a"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#202020"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#304a7d"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#202020"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#2c6675"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#2c6675"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#202020"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#202020"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#3a4762"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#0e1626"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        }
    ];
}
<div class="gfg">
    <app-header [logoSrc]="logoSrc" [gdpr]="true"></app-header>

    <div class="content">
        <div class="top-txt-box margin-bottom">
            <img id="picture" class="margin-bottom" src="/assets/images/taxi-ordering.png">
            <div class="top-txt"> {{ getTopText() }}</div>
            <div class="top-txt hotel-name" >{{ hotelName }}</div>
            <div class="top-txt"> {{'selfServiceStation_mobile_secondText' | translate }}</div>
        </div>
    
        <div class="button-box">
            <div class="button-row">
                <button mat-flat-button class="button margin" (click)="continue(1)" color="primary">
                    {{ 'selfServiceStation_orderTaxiNow' | translate }}
                </button>
                <button mat-flat-button class="button margin" (click)="continue(0)" color="newsecondary">
                    {{ 'selfServiceStation_orderTaxiFixedPrice' | translate }}
                </button>
            </div>
            <div class="button-row">
                <button mat-flat-button class="button margin" (click)="continue(3)" color="newprimary">
                    {{ 'selfServiceStation_orderTaxiHotel' | translate }}
                </button>
                <button *ngIf="showLocationOrAiport()" mat-flat-button class="button margin" (click)="continue(4)" color="newprimary">
                    {{ getLocationTranslation(0) }}
                </button>
                <button *ngIf="!showLocationOrAiport()" mat-flat-button class="button margin" (click)="continue(2)" color="newprimary">
                    {{ 'selfServiceStation_orderTaxiAirport' | translate }}
                </button>
            </div>
        </div>
    </div>

    <app-bottom-banner class="bottom" [logoSrc]="logoSrc"></app-bottom-banner>
</div>

import { Component, Input } from '@angular/core';
import { GdprPopupComponent } from '../gdpr-popup/gdpr-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { TaxiContactComponent } from 'src/app/shared/components/taxi-contact/taxi-contact.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() logoSrc: string | undefined;
  @Input() gdpr: boolean = false;
  @Input() name: string | undefined;

  isHotel = false;

  bannedURLS: string[] = ['/resturant', '/restaurant', '/fast-order', '/assistant', '/qr', '/mobile', '/station', '/status', '/voucher', '/pay', '/cancel-taxi', '/tracking', '/reciept', '/info', '/self-service-station'];

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    this.isHotel = this.checkIfPc(this.bannedURLS);
  }

  openGDPRPopUp(): void {
    this.dialog.open(GdprPopupComponent, {
      height: '90%',
      width: '90%'
    });
  }

  customerSupportPopUp(): void {
    this.dialog.open(TaxiContactComponent, {
      height: '50%',
      width: '50%'
    });
  }

  checkIfPc(bannedUrls: string[]): boolean {
    // Check if the URL contains the words it shouldnt have in bannedurls
    const currentUrl = window.location.href.toLowerCase();
    const urlContainsBannnedUrl = !bannedUrls.some(word => currentUrl.includes(word));

    return urlContainsBannnedUrl;
  }

  showName(): boolean {
    return false;
    //return this.name !== undefined
  }
}

<div class="forms search" disabled>
    <div class="form-group">
        <mat-icon class="map">location_on</mat-icon>
        <input appGooglePlace
               required
               #inputField
               [disableAutocomplete]="getShowAlternative()"
               (onAddressChange)="handleInputFieldAddressChange($event)"
               type="text"
               placeholder="{{ (fromHotel ? 'chooseDestinationToPickup' : 'chooseDestination') | translate }}"
               class="form-control input-shadow"
               [value]="destinationName"
               (focus)="inputFieldFocused()"
               (keyup)="inputFieldKeyUp($event)"
               [options]="inputFieldOptions"
               [disabled]="disabled"
               (input)="handleInputChange($event)"/>
        <mat-icon class="srch">search</mat-icon>

        <div class="destinationDropdownSuggestions"
             [hidden]="!suggestionsShown">
            <div class="list distance">
                <mat-radio-group color="primary"
                                 #standardSuggestionsElement
                                 (keydown)="suggestionsElementKeyDown($event)"
                                 [value]="destinationName">
                    <mat-radio-button *ngFor="let fixedDestination of fixedDestinations"
                                      [value]="fixedDestination.name"
                                      (change)="handleSuggestionsElementChange(fixedDestination)"
                                      (click)="focusUsingMouse()">
                        <div class="row">
                            <div class="box">
                                <h4>{{ fixedDestination.name }}</h4>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="destinationDropdownSuggestions"
             [hidden]="!alternativeShown">
            <div class="list distance">
                <mat-radio-group color="primary"
                                 #alternativeSuggestionsElement
                                 (keydown)="suggestionsElementKeyDown($event)"
                                 [value]="destinationName">
                    <mat-radio-button *ngFor="let alternativeDestination of alternativeDestinations"
                                      [value]="alternativeDestination.address"
                                      (change)="handleSuggestionsElementChange(alternativeDestination)"
                                      (click)="focusUsingMouse()">
                        <div class="row">
                            <div class="box">
                                <h4>{{ alternativeDestination.name }}</h4>
                            </div>
                        </div>
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div *ngIf="showErrors"
             class="alert alert-danger">
            <div *ngIf="!destinationName">
                {{ "destinationRequired" | translate }}
            </div>
        </div>

        <div class="address">{{ location?.address }}&nbsp;</div>
    </div>
</div>
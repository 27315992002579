import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GetTaxiService } from './get-taxi.service';
import { HotelService, PaymentOption } from './hotel.service';
import { ParameterService } from './parameter.service';
import { IHotelInfo } from '../models/hotel-info.interface';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService {
  private hotelId?: string;
  private accessToken?: string;

  private receipts: BehaviorSubject<Receipt[]> = new BehaviorSubject(new Array<Receipt>());
  updateTimer?: number;
  shouldUpdate: boolean = true;

  constructor(private getTaxiService: GetTaxiService, parameterService: ParameterService, private hotelService: HotelService) {
    parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;

      if (this.hotelId !== undefined && this.accessToken !== undefined)
        this.update();
    });

    hotelService.paymentOptions.subscribe(hotelInfo => {
      this.shouldUpdate = ((this.hotelService.paymentOptionsValue & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill);
    });
  }

  update() {
    clearTimeout(this.updateTimer);
    if (!this.shouldUpdate)
      return;

    var today = new Date();
    var date = new Date(today.getFullYear(), today.getMonth(), 2, 0, 0, 0, 0);
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      date: date.toISOString(),
      includeFutureReceipts: true
    };

    this.getTaxiService.getReceipts(body).subscribe(async data => {
      this.receipts.next(data);
      this.updateTimer = window.setTimeout(() => this.update(), 30000);
    });
  }

  connectReceipts(): Observable<Receipt[]> {
    return this.receipts.pipe();
  }
}

interface Receipt {
  phoneNumber: string;
  roomNumber: string;
  applied: boolean;
  deleted: boolean;
  paymentOption: PaymentOption;

  bookingNumber: string;
  flightArrivalTime?: Date;
  flightNumber: string;
  guestName: string;

  rides: {
    id: string;
    createdAt: Date;
    pickUpTime: Date;
    rideType: number;
    customerPrice: number;
    sharedRideSavings?: number;
    applied: boolean;
  }[];
}

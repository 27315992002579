import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IHotelInfo } from 'src/app/models/hotel-info.interface';
import { HotelService } from 'src/app/services/hotel.service';

@Component({
  selector: 'app-language-chooser',
  templateUrl: './language-chooser.component.html',
  styleUrls: ['./language-chooser.component.scss']
})
export class LanguageChooserComponent {
  dropdownOpen = false;
  defaultLang = "en";
  hotelLang = "en";

  constructor(private translate: TranslateService, private hotelService: HotelService) {
    if (this.hotelService.hotelInfoValue) {
      this.updateLanguage(this.hotelService.hotelInfoValue.language);
    }
    else {
      this.hotelService.hotelInfo.subscribe(hotelInfo => {
        this.hotelLang = hotelInfo.language;
        this.updateLanguage(hotelInfo.language);
      });
    }
  }

  updateLanguage(lang: string) {
    this.hotelLang = lang;
    this.defaultLang = lang;
    let chosen = sessionStorage.getItem("langChosen");
    if (chosen != "true") {
      this.translate.setDefaultLang(lang);
      localStorage.setItem('lang', lang);
    }
  }

  useLanguage(lang: any) {
    this.translate.setDefaultLang(lang);
    localStorage.setItem('lang', lang);
    sessionStorage.setItem("langChosen", "true");
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
  }
}

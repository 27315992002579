import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IDestination } from '../../models/destination.interface';

export interface InitPopupQRStandardComponentArgs {
  logoSrc?: string;
}

@Component({
  selector: 'app-init-popup-qr',
  templateUrl: './init-popup-qr-standard.component.html',
  styleUrls: ['./init-popup-qr-standard.component.scss']
})

export class InitPopupQRStandardComponent {

  private _logoSrc: string = 'assets/images/logo_white.svg';
  locations: IDestination[] | undefined = undefined;

  set logoSrc(value: string | undefined) {
    if (value) this._logoSrc = value;
  }

  get logoSrc(): string | undefined {
    return this._logoSrc;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: InitPopupQRStandardComponentArgs, public dialogRef: MatDialogRef<InitPopupQRStandardComponent>, private translate: TranslateService) {
    this.logoSrc = data.logoSrc;
  }

  showLocation(): boolean {
    if (this.locations?.length == 1)
      return true;
    else return false;
  }

  getLocationTranslation(number: number): string {
    return this.translate.instant('selfServiceStation_orderTaxiSpecial').replace('{nameOfLocation}', this.locations?.[number].name?.replace("*", ""))
  }

  continue(value: number): void {
    this.dialogRef.close(value);
  }

  updateComponentData(logoSrc: string | undefined, specialLocations: IDestination[]) {
    this.logoSrc = logoSrc;
    this.locations = specialLocations;
  }
}
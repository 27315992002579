<div class="header">
    <div *ngIf="showName()" class="name">
        <strong>{{ name }}</strong>
    </div>
    <div class="overlay">
        <div class="header-boxes" [ngClass]="isHotel ? 'logo-box-hotel' : 'logo-box-mobile'">
            <img *ngIf="!isHotel" class="mobile-logo" [src]="logoSrc"/>
            <img *ngIf="isHotel" class="hotel-logo" [src]="logoSrc"/>
        </div>

        <div class="header-boxes"> 
            <app-top-menu *ngIf="isHotel"></app-top-menu>
        </div>

        <div class="header-boxes" *ngIf="isHotel">
            <div class="side-buttons">
                <button mat-button class="button" (click)="customerSupportPopUp()">
                    {{ "taxiSupport" | translate }}
                </button>
                <div class="supportMail">
                    <a href="mailto:support@commuteapp.dk">Support mail</a>
                </div>
            </div>
        </div>

        <div class="header-boxes">
            <img *ngIf="gdpr" (click)="openGDPRPopUp()" class="gdpr" src="../../../../assets/images/gdpr.png">
            <app-language-chooser></app-language-chooser>
        </div>        
    </div>
</div>

<div *ngIf="hasMultipleTaxiCompanies()" class="taxi-choose">
  <mat-radio-group color="primary" class="button-section" [(ngModel)]="selectedTaxiCompany" (ngModelChange)="selectedTaxiCompany = $event">
    <mat-radio-button *ngFor="let taxiName of taxiNames; let i = index" 
      [value]="taxiName" 
      [ngClass]="{ 'first': i === 0, 'last': i === taxiNames.length - 1 }"
      (change)="onTaxiBoxClick(taxiName)"
      class="custom-radio-box">
      <div class="taxi-item">
        <!-- Image element with dynamic src -->
        <img *ngIf="isLogoAvailable[taxiName]" 
          [src]="'assets/images/taxilogos/' + taxiName + '.png'" 
          alt="{{ taxiName }} logo" 
          class="taxi-logo" 
          (error)="isLogoAvailable[taxiName] = false" />
        <h4 class="taxi-name">{{ taxiName }}</h4>
      </div>
    </mat-radio-button>
  </mat-radio-group>
</div>

<form [formGroup]="secondDestination"
      (submit)="submit()"
      #secondDestinationForm="ngForm"
      *ngIf="messageShow==false">
  <div class="list distance">
    <h2 class="title">
      {{ "list-destinations-title" | translate}}
    </h2>
    <mat-radio-group color="primary"
                     aria-label="Select an option"
                     formControlName="destination">
      <mat-radio-button *ngFor="let destination of otherDestinations; let i = index"
                        [value]="destination"
                        [ngClass]="{ 'bold-button': i < boldCount }">
        <div class="row">
          <div class="box">
            <h4>{{ destination.name }}</h4>
            <!-- <span class="subtitle">{{ destination.address }}</span> -->
          </div>
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </div>

  <div class="search-div">
    <div class="rows search-box">
      <div>
        <h2 class="title">
          {{ "address" | translate }}
        </h2>
      </div>
      <div *ngIf="!showAirports">
        <app-destination-selector *ngIf="!toHotelActive"
          formControlName="destination"
          [fromHotel]="toHotelActive"
          [searchEngine]="getSearchEngine()"
          [hotelInfo]="hotelInfo"
          [showErrors]="formSubmit && !validated"></app-destination-selector>

        <app-destination-selector *ngIf="toHotelActive"
            formControlName="from"
            [fromHotel]="toHotelActive"
            [searchEngine]="getSearchEngine()"
            [hotelInfo]="hotelInfo"
            [showErrors]="formSubmit && !validated"></app-destination-selector>
      </div>
      <div *ngIf="showAirports" class="airport-chooser">
        <mat-select class="chooser"
          [(value)]="selectedAirport"
          (selectionChange)="selectAirport($event)">
          <mat-option *ngFor="let airport of airports" [value]="airport">{{airport.name}}</mat-option>
        </mat-select>
        <button mat-button class="button" type="button" (click)="resetAirport()">Reset Airport</button>
      </div>
    </div>

    <div class="rows" *ngIf="noDestinationAllowed"> 
      <div>
        <h2 class="title">
          {{ "direct-taxi-option-title" | translate }}
        </h2>
      </div>

      <div>
        <mat-checkbox class="button" 
                      color="secondary" 
                      (change)="noDestination($event)"
                      [checked]="noDestChecked">
          {{ "direct-taxi-option" | translate }}
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="pick-time">
    <ion-label class="bold"> {{ "guestPickedUp" | translate }} </ion-label>
    <app-date-time-picker formControlName="pickupTime"
                          [autoIncrementTime]="true"
                          (change)="onChangeDate()"></app-date-time-picker>
  </div>

  <div class="forms">
    <div class="float-container">
      <div *ngIf="hideEverything==false && getCustomer" class="form-group roomNo">
        <ion-label class="bold">{{ "roomNo" | translate }}</ion-label>
        <ion-input type="text"
                    placeholder="“253“"
                    class="form-controls input-placeholder"
                    formControlName="roomNo"></ion-input>
      </div>

      <div *ngIf="hideEverything==false && getCustomer">
        <button type="button" id="roomNumberButton" (click)="roomNumberGetInfo()" class="roomNumberButton">✓</button>
      </div>

      <div *ngIf="hideEverything==false && hideNameAndPhone==false && getCustomer" class="form-group">
        <ion-label class="bold">{{ "guestName" | translate }}</ion-label>
        <ion-input formControlName="guestName"
                   type="text"
                   [placeholder]="translate.instant('guest_name_placeholder')"
                   class="form-controls" input-placeholder></ion-input>
      </div>

      <div *ngIf="hideEverything==false && hideNameAndPhone==false && !getCustomer" class="form-group">
        <ion-label class="bold">{{ "roomno-guestName" | translate }}</ion-label>
        <ion-input formControlName="guestName"
                   type="text"
                   [placeholder]="translate.instant('guest_name_placeholder')"
                   class="form-controls" input-placeholder></ion-input>
      </div>

      <div *ngIf="hideEverything==false && hideNameAndPhone==false">
        <ion-label class="bold">{{ "phoneNoLabel" | translate }}</ion-label>
        <app-phone-number-input-pc 
          type="button"
          class="phone-number"
          (onPressNumber)="phoneSelect($event)"
          (click)="scrollToBottom()"
          [hotelId]="hotelId"
          [phoneNumber]="phoneNumber">
        </app-phone-number-input-pc>
      </div>

      <div *ngIf="hideEverything==false && showAirports" class="form-group m-r-10">
        <ion-label class="bold">{{ "flightNo" | translate }}</ion-label>
        <ion-input formControlName="flightNo"
                   type="text"
                   placeholder="e.g. “SK586“"
                   class="form-controls" input-placeholder></ion-input>
      </div>
      
      <div *ngIf="getHideEverythingExpand()">
        <button mat-button type="button" (click)="hideEverythingClick()"  class="hideEverything-expand-button">
          <span>{{ "hideEverything-expand" | translate }}</span>
        </button>
      </div>
    </div>

    <div *ngIf="!validated && formSubmit" class="alert alert-danger strange-margin">
      <div *ngIf="guestName?.errors">
        {{ "guestNameRequired" | translate }}
      </div>
      <div *ngIf="roomNo?.errors">
        {{ "roomNumberMissing" | translate }}
      </div>
      <div *ngIf="phoneNo?.errors">
        {{ "phone-number-error" | translate }}
      </div>
      <div *ngIf="flightNo?.errors?.required">
        {{ "flightNoRequired" | translate }}
      </div>
    </div>

    <app-apply-to-bill-box *ngIf="showApplyToBill"
        formControlName="paymentOption"
        [showErrors]="formSubmit"
        [disabled]="noDestinationSelected"
        class="apply-to-bill-box"></app-apply-to-bill-box>
  </div>

  <div *ngIf="getHideEverythingCollapse()">
    <button mat-button type="button" (click)="hideEverythingClick()"  class="hideEverything-collapse-button">
      <span>{{ "hideEverything-collapse" | translate }}</span>
    </button>
  </div>

  <div *ngIf="largeTaxi && formSubmit"
    class="alert alert-danger">
    <div *ngIf="largeTaxi?.errors?.required">
      {{ "pleaseSelectOption" | translate }}
    </div>
  </div>

  <app-payment-option *ngIf="!hotelSystemIntegrationApplyToBill==true"
                      formControlName="paymentOption"
                      [paymentOptionFilterMethod]="paymentOptionFilter"
                      [showErrors]="formSubmit"
                      [isLargeTaxi]="largeTaxi?.value"></app-payment-option>

  <div class="buttons-box" [ngClass]="{ 'shift': showingCustomerPrice }">
    <app-order-button [ngClass]="{ 'showing-price': showingCustomerPrice }" [paymentOption]="paymentOption?.value"></app-order-button>

    <div>
      <button mat-button type="button" (click)="showTaxiOptions()" class="taxi-options-button">
        <span>{{ "taxi-options" | translate }}</span>
      </button>
    </div>
  </div>

  <div *ngIf="showOption" class="modal-overlay">
    <div class="modal-content">
      <div class="m-b-10">
        <ion-label class="bold title">{{ "possibilityToOrder" | translate }}</ion-label>
      </div>

      <div class="number m-b-10" 
            *ngIf="displayOption('number of cars')">
        <ion-label class="small-title">{{ "numberOfCarsLabel" | translate }}</ion-label>
        <div class="content">
          <div class="number-width">
            <ion-input max="25"
              min="1"
              type="number"
              formControlName="numberOfCars"
              class="with-outline"
              [ngClass]="{'alert alert-danger': numberOfCars?.errors?.required || numberOfCars?.errors?.min || numberOfCars?.errors?.max}">
            </ion-input>
          </div>
          <button mat-icon-button type="button" class="circle-button" (click)="decrement('numberOfCars')">
            <mat-icon>remove</mat-icon>
          </button>
          <button mat-icon-button type="button" class="circle-button" (click)="increment('numberOfCars')">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>

      <div class="commentbox m-b-10"
            *ngIf="displayOption('comment')">
        <ion-label class="small-title">{{ "commentToDriverLabel" | translate }}</ion-label>
        <textarea
          class="textarea"
          id="multiLineInput"
          formControlName="commentToDriver"
          rows="3"
          placeholder="e.g. “”">
        </textarea>
      </div>

      <div class="options-table">
        <ion-label class="small-title">{{ "options" | translate }}</ion-label>
        <mat-checkbox class="mat-secondary custom-border"
                      aria-label="Select an option"
                      formControlName="largeTaxi"
                      (change)="isShowShare($event)"
                      [disabled]="canOrderLargeTaxi==false"
                      *ngIf="displayOption('large')">
          <span class="mat-checkbox-label">
            {{ largeTaxiTranslation }}
          </span>
        </mat-checkbox>

        <mat-checkbox class="mat-secondary custom-border"
                      aria-label="Select an option"
                      (change)="isShowShare($event)"
                      formControlName="stationCar"
                      *ngIf="displayOption('estate car')">{{ "stationCar" | translate }}
        </mat-checkbox>

        <mat-checkbox class="mat-secondary"
                      aria-label="Select an option"
                      (change)="isShowShare($event)"
                      formControlName="electricCar"
                      *ngIf="displayOption('electric car')">{{ "electricCarLabel" | translate }}
        </mat-checkbox>

        <mat-checkbox class="mat-secondary"
                      aria-label="Select an option"
                      (change)="isShowShare($event)"
                      formControlName="animal"
                      *ngIf="displayOption('bring pet')">{{ "animalLabel" | translate }}
        </mat-checkbox>

        <mat-checkbox class="mat-secondary"
                      aria-label="Select an option"
                      (change)="isShowShare($event)"
                      formControlName="bike"
                      *ngIf="displayOption('bring bike')">{{ "bikeLabel" | translate }}
        </mat-checkbox>

        <mat-checkbox class="mat-secondary"
                aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="carseat"
                *ngIf="displayOption('carseat')">{{ "carseat" | translate }}
        </mat-checkbox>

        <mat-checkbox class="mat-secondary"
                aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="oneBoosterSeat"
                *ngIf="displayOption('oneBoosterSeat')">{{ "oneBoosterSeat" | translate }}
        </mat-checkbox>

        <mat-checkbox class="mat-secondary" 
                aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="twoBoosterSeats"
                *ngIf="displayOption('twoBoosterSeats')">{{ "twoBoosterSeats" | translate }}
        </mat-checkbox>

        <mat-checkbox class="mat-secondary"
                aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="childSeat"
                *ngIf="displayOption('childSeat')">{{ "childSeat" | translate }}
        </mat-checkbox>

        <mat-checkbox class="mat-secondary"
                aria-label="Select an option"
                (change)="isShowShare($event)"
                formControlName="hybridCar"
                *ngIf="displayOption('hybridCar')">{{ "hybridCar" | translate }}
        </mat-checkbox>

        <app-hotel-payment *ngIf="displayOption('hotel pays')"
                            (hotelPayment)="hotelPaymentChanged($event)"></app-hotel-payment>
      </div>

      <div class="taxiInfo"
        *ngIf="showOnLarge && canOrderLargeTaxi">
        <h2>
          <mat-icon>person</mat-icon> {{ "chooseNumberOfGuests" | translate }}
        </h2>
        <div class="innercontainer">
          <div class="guests">
            <span class="steps">5</span>
            <span class="steps">6</span>
            <span class="steps">7</span>
            <span class="steps">8</span>
          </div>
          <mat-slider class="customSlide"
                      thumb
                      tickInterval="1"
                      min="5"
                      max="8"
                      formControlName="numberOfGuests"
                      [value]="1"></mat-slider>
        </div>
      </div>
      
      <div class="button-div">
        <button mat-button type="button" (click)="showTaxiOptions()" class="close-button">
          {{ "save" | translate }}
        </button>
      </div>
    </div>
  </div>

  <app-bonus-dashboard></app-bonus-dashboard>
</form>

<div class="taxi-ordered thankyou" *ngIf="messageShow">
  <div *ngIf="showLargeTaxiLoading">
    <div *ngIf="loadingDone==false">
      <h3>{{ "processingLargeTaxiOrder" | translate }}</h3>
      <mat-progress-bar [mode]="'determinate'" [value]="progressValue" class="progress-bar">
      </mat-progress-bar>
    </div>
    <div *ngIf="loadingDone">
      <h3>{{ largeTaxiCarResponse }}</h3>
      <h3 *ngIf="showEmote" class="emote">{{ "smileyEmoji" | translate }}</h3>
    </div>
  </div>
  <div *ngIf="showLargeTaxiLoading==false">
    <h3>{{ "thankYouForYourOrderShort" | translate }}</h3>
    <h3 *ngIf="showEmote" class="emote">{{ "smileyEmoji" | translate }}</h3>
  </div>
</div>
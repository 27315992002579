import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { IRide } from 'src/app/services/models/ride.model';

@Component({
  selector: 'app-update-ride-dialog',
  templateUrl: './update-ride-dialog.component.html',
  styleUrls: ['./update-ride-dialog.component.scss']
})
export class UpdateRideDialogComponent {
  ride: IRide | undefined;
  rideId: string;
  loading!: HTMLIonLoadingElement;

  public rideFormGroup: FormGroup;
  formSubmit: boolean = false;

  constructor(public dialog: MatDialogRef<UpdateRideDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public getTaxiService: GetTaxiService, private fb: FormBuilder, public translate: TranslateService, public alertController: AlertController, public loadingController: LoadingController) {
    this.rideFormGroup = fb.group({
      destination: [null],
      from: [null],
      roomNo: [null],
      commentToDriver: [null],
      guestName: [null],
      phoneNo: [null, [Validators.minLength(8), Validators.pattern(/^((00|\+)\d{8,16})$/)]],
      pickupTime: [new Date(), Validators.required],
      largeTaxi: [false],
      stationCar: [false],
      animal: [false],
      bike: [false],
      electricCar: [false],
      numberOfGuests: [4],
      carseat: [false],
      oneBoosterSeat: [false],
      twoBoosterSeats: [false],
      childSeat: [false],
      hybridCar: [false],
      flightNo: [null],
    });

    this.rideId = data.id;
    this.getTaxiService.getRideToUpdate(this.rideId).subscribe((fetchedData: IRide) => {
      this.ride = fetchedData;

      this.from?.setValue(fetchedData.from, { emitEvent: false });
      this.pickupTime?.setValue(fetchedData.pickupTime, { emitEvent: false });

      this.commentToDriver?.setValue(fetchedData.comment, { emitEvent: false });
      this.guestName?.setValue(fetchedData.guestName, { emitEvent: false });
      this.phoneNo?.setValue(fetchedData.phoneNumber, { emitEvent: false });
      this.roomNo?.setValue(fetchedData.roomNumber, { emitEvent: false });

      if (fetchedData.flightNumber)
        this.flightNo?.setValue(fetchedData.flightNumber, { emitEvent: false });

      if (fetchedData.to)
        this.destination?.setValue(fetchedData.to, { emitEvent: false });
    })
  }

  phoneSelect(event: any): void {
    this.phoneNo?.setValue(event?.tel);
  }

  saveSettings() {
    //For some reason the value changes to a string if not touched? Not sure why but dont have to time do a cleaner fix atm. 
    //Just check if destination value is string and the backend will set the destination to original dest... - DK-09-08-24
    this.presentLoading();
    var to: Location | null;
    if (this.destination?.value && typeof this.destination?.value !== 'string')
      to = this.destination?.value;
    else
      to = null;

    const updatedRide = {
      rideId: this.rideId,
      from: this.from?.value,
      to: to,
      pickupTime: this.pickupTime?.value,
      comment: this.commentToDriver?.value,
      guestName: this.guestName?.value,
      phoneNumber: this.phoneNo?.value,
      roomNumber: this.roomNo?.value,
      flightNo: this.flightNo?.value,
    };

    this.getTaxiService.updateRide(updatedRide).subscribe(() => {
      this.dialog.close(true);
      this.hideLoading();
    }, async (error) => {
      this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });
  }

  flightNoKeyPress(event: any) {
    // No spaces (character code 32) allowed
    if (event.keyCode === 32)
      event.preventDefault();
  }

  onNoClick() {
    this.dialog.close(false);
  }

  get commentToDriver() {
    return this.rideFormGroup.get('commentToDriver');
  }

  get roomNo() {
    return this.rideFormGroup.get('roomNo');
  }

  get animal() {
    return this.rideFormGroup.get('animal');
  }

  get bike() {
    return this.rideFormGroup.get('bike');
  }

  get electricCar() {
    return this.rideFormGroup.get('electricCar');
  }

  get carseat() {
    return this.rideFormGroup.get('carseat');
  }

  get oneBoosterSeat() {
    return this.rideFormGroup.get('oneBoosterSeat');
  }

  get twoBoosterSeats() {
    return this.rideFormGroup.get('twoBoosterSeats');
  }

  get childSeat() {
    return this.rideFormGroup.get('childSeat');
  }

  get guestName() {
    return this.rideFormGroup.get("guestName");
  }

  get phoneNo() {
    return this.rideFormGroup.get('phoneNo');
  }

  get destination() {
    return this.rideFormGroup.get('destination');
  }

  get from() {
    return this.rideFormGroup.get('from');
  }

  get pickupTime() {
    return this.rideFormGroup.get('pickupTime');
  }

  get largeTaxi() {
    return this.rideFormGroup.get('largeTaxi');
  }

  get numberOfGuests() {
    return this.rideFormGroup.get('numberOfGuests');
  }

  get stationCar() {
    return this.rideFormGroup.get('stationCar');
  }

  get hybridCar() {
    return this.rideFormGroup.get('hybridCar');
  }

  get flightNo() {
    return this.rideFormGroup.get('flightNo');
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }
}
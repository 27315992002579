<ion-content>
  <div class="container"> 
    <app-header [logoSrc]="hotelInfo?.logoSrc"></app-header>

    <div class="left-container">
      <app-coin-tree-counter-container></app-coin-tree-counter-container>
    </div>

    <div class="main-container">
      <div class="taxiHotel">
        <div class="tabs">
          <form [formGroup]="secondDestination"
            #secondDestinationForm="ngForm"
            class="orderOptions">
              
            <div class="pick-time-and-price">
                <div>
                  <label style="color: white;">{{ "guestPickedUp" | translate }}</label>
                  <div>
                    <app-date-time-picker formControlName="pickupTime"
                                          [autoIncrementTime]="true"
                                          (change)="onChangeDate()"></app-date-time-picker>
                  </div>
                </div>
            </div>
            
            <div class="order-button">
              <div class="order-submit-wrapper">
                <button mat-flat-button
                        class="order-submit"
                        [disabled]="!secondDestination.valid"
                        (click)="submit()"
                        color="primary">{{ "selfServiceStation_orderButtonText" | translate }}</button>
              </div>            
            </div>
          </form>

          <div class="upcomming-rides-div">
            <div class="no-rides-planned" *ngIf="rides.length === 0">
              {{ 'restaurantNoRides' | translate }}
          </div>
          <div *ngIf="rides.length > 0" class="upcomming-rides">
              <table>
                <thead>
                  <tr>
                    <th>{{ 'pickUpTime' | translate }}</th>
                    <th>{{ 'status' | translate }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ride of rides">
                    <td *ngIf="!getCurrentTime(ride)">{{ ride.pickupTime + 'Z' | date:'HH:mm' }}</td>
                    <td *ngIf="getCurrentTime(ride)">{{ "shortTaxiStatusOnItsWay" | translate }}</td>
                    <td *ngIf="statusOrdered(ride)"
                        class="status">{{ 'shortTaxiStatusOrdered' | translate }}</td>
                    <td *ngIf="statusOnItsWay(ride)"
                          class="status">{{ 'shortTaxiStatusOnItsWay' | translate }}</td>
                    <td *ngIf="statusTaximeterStarted(ride)"
                          class="status">{{ 'shortTaxiStatusTaximeterStarted' | translate }}</td>
                    <td *ngIf="statusInVain(ride)"
                          class="status">{{ 'shortTaxiStatusInVain' | translate }}</td>
                    <td *ngIf="statusNoShow(ride)"
                          class="status">{{ 'shortTaxiStatusNoShow' | translate }}</td>
                    <td *ngIf="statusUnknown(ride)"
                          class="status">{{ 'shortTaxiStatusUnknown' | translate }}</td>  
                    <td *ngIf="statusArrived(ride)"
                          class="status">{{ 'shortTaxiStatusArrived' | translate }}</td>  
                    <td *ngIf="statusCancelled(ride)"
                          class="status">{{ 'shortTaxiStatusCancelled' | translate }}</td>  
                    <td *ngIf="statusCompleted(ride)"
                          class="status">{{ 'shortTaxiStatusCompleted' | translate }}</td>  
                    <td *ngIf="!statusTaximeterStarted(ride) 
                            && !statusUnknown(ride) 
                            && !statusInVain(ride)  
                            && !statusNoShow(ride)
                            && !statusCancelled(ride)
                            && !statusCompleted(ride)"
                            class="right">
                          <button mat-stroked-button color="danger" (click)="cancelRide(ride)">
                            {{'cancelTaxi' | translate}}
                          </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="google-map">
            <app-google-map [carCoordinates]="taxiCars"></app-google-map>
          </div>

        </div>
      </div>
    </div>

    <div class="right-container">
    </div>
  </div>
</ion-content>
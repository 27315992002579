import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(private router: Router, private translate: TranslateService, private parameterService: ParameterService, public service: GetTaxiService) {
    let today = new Date();
    this.controlDate = new Date(today.getFullYear(), today.getMonth(), 2, 0, 0, 0, 0);
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;
    });
  }

  hotelId: any;
  airportId: any;
  accessToken: any;
  controlDate: Date;

  ngOnInit(): void {
    let lang = localStorage.getItem("lang");
    if (lang == 'en') {
      document.getElementById('button_en')?.classList.add('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'da') {
      document.getElementById('button_da')?.classList.add('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'no') {
      document.getElementById('button_no')?.classList.add('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'se') {
      document.getElementById('button_se')?.classList.add('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'fi') {
      document.getElementById('button_fi')?.classList.add('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
    }
    else {
      document.getElementById('button_de')?.classList.add('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }

    this.getReceipts(this.controlDate); //***Is this used?
  }

  goToBookTaxi() {
    this.router.navigateByUrl('/book-a-taxi?hotelId=' + this.hotelId + '&accessToken=' + this.accessToken +
      '&airportId=' + this.airportId);
  }

  gotoControlTrip() {
    this.router.navigateByUrl('/control-trips?hotelId=' + this.hotelId + '&accessToken=' + this.accessToken +
      '&airportId=' + this.airportId);
  }

  gotoBillHistory() {
    this.router.navigateByUrl('/bill-histories?hotelId=' + this.hotelId + '&accessToken=' + this.accessToken +
      '&airportId=' + this.airportId);
  }

  useLanguage(lang: any) {
    if (lang == 'en') {
      document.getElementById('button_en')?.classList.add('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'da') {
      document.getElementById('button_da')?.classList.add('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'no') {
      document.getElementById('button_no')?.classList.add('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'se') {
      document.getElementById('button_se')?.classList.add('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'fi') {
      document.getElementById('button_fi')?.classList.add('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
    }
    else {
      document.getElementById('button_de')?.classList.add('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }

    if (lang == 'se')
      this.translate.setDefaultLang('en');
    else
      this.translate.setDefaultLang(lang);
    localStorage.setItem("lang", lang);
    sessionStorage.setItem("langChosen", "true");
  }

  showCount = 0;
  reciepts: any;

  async getReceipts(tripDate: Date) {
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      date: this.controlDate.toISOString()
    };
    this.service.getReceipts(body).subscribe(async (data) => {
      this.reciepts = data;
      for (let receipt of this.reciepts) {
        if (receipt.applied != true)
          this.showCount++;
      }
    }, async (error) => {
      console.log(error);
    });
  }
}

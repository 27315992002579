import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/services/page.service';
import { GetTaxiService } from '../../services/get-taxi.service';
import { ICoordinate } from '../../models/coordinate.interface';
import { ReceptionMapperService } from '../../shared/mappers/reception-mapper.service';
import { Subject } from 'rxjs';
import { ParameterService } from 'src/app/services/parameter.service';
import { HotelService, HotelSystemIntegration, PaymentOption } from 'src/app/services/hotel.service';
import { MatDialog } from '@angular/material/dialog';
import { SendLinkPopupComponent } from 'src/app/shared/components/send-link-popup/send-link-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { NewsPopupComponent } from 'src/app/shared/components/news-popup/news-popup.component';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { PriceService } from 'src/app/services/price.service';
import { CancelledByDriverComponent } from 'src/app/shared/components/cancelled-by-driver/cancelled-by-driver.component';
import { DisplayOptions, HotelSettings, RideType } from 'src/app/models/enums';
import { InactivityService } from 'src/app/services/inactivity.service';
import { IHotelInfo } from 'src/app/models/hotel-info.interface';

@Component({
  selector: 'app-book-taxi',
  templateUrl: './book-taxi.component.html',
  styleUrls: ['./book-taxi.component.scss']
})
export class BookTaxiComponent implements OnInit {
  hotelInfo?: IHotelInfo;
  subscr$ = new Subject();
  showCancelledApplyToBill = false;
  updateTimer?: number;
  logoSrc?: string;
  showName: string | undefined;
  dropDownOpen: boolean = false;
  markerId: string = '';
  hasNewRides: boolean = false;

  toAirportTab: boolean = false;
  otherDestinationTab: boolean = true;
  fromAirportTab: boolean = false;
  taxiSupportTab: boolean = false;

  taxiCars: ICoordinate[] = [];

  constructor(
    private getTaxiService: GetTaxiService,
    private parameterService: ParameterService,
    private pageService: PageService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private taxiConfigurationService: TaxiConfigurationService,
    private priceService: PriceService,
    private inactivityService: InactivityService,
    private hotelService: HotelService) {

    getTaxiService.getTaxiLocations().subscribe(dataCoordinate => {
      this.taxiCars = dataCoordinate?.length ? dataCoordinate
        .map((el: any) => ReceptionMapperService.mapTaxiMapFormToICoordinate(el)) : [];
    });
    setInterval(() => {
      getTaxiService.getTaxiLocations().subscribe(dataCoordinate => {
        this.taxiCars = dataCoordinate?.length ? dataCoordinate
          .map((el: any) => ReceptionMapperService.mapTaxiMapFormToICoordinate(el)) : [];
      });
    }, 30 * 1000); //30 seconds
  }

  ngOnInit(): void {
    this.pageService.currentHeaderTranslationKey = 'orderTaxiButton';
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;
    });

    if (this.hotelService.hotelInfoValue) {
      this.hotelInfo = this.hotelService.hotelInfoValue;
      this.updateHotelInfo();
    }
    else {
      this.hotelService.hotelInfo.subscribe(hotelInfo => {
        this.hotelInfo = hotelInfo;
        this.updateHotelInfo();
      });
    };

    this.update();
  }

  ngOnDestroy(): void {
    this.subscr$.next();
    this.subscr$.complete();

    this.inactivityService.resetToOriginalState(); // Restore original title/favicon on component destruction
  }

  updateHotelInfo() {
    this.logoSrc = this.hotelInfo!.logoSrc;
    localStorage.setItem('logoSrc', this.logoSrc!);

    if ((this.hotelInfo!.displayOptions & DisplayOptions.DisplayNewsPopup) == DisplayOptions.DisplayNewsPopup) {
      this.dialog.open(NewsPopupComponent, {
        disableClose: true
      });
    }

    if ((this.hotelInfo!.integrationOptions & HotelSystemIntegration.AddToBill) === HotelSystemIntegration.AddToBill)
      this.showCancelledApplyToBill = true;

    if ((this.hotelInfo!.hotelSettings & HotelSettings.ShowNameOnRecep) === HotelSettings.ShowNameOnRecep)
      this.showName = this.hotelInfo?.name
  }

  toggleDropdown(): void {
    this.dropDownOpen = !this.dropDownOpen;
  }

  closeDropdown(): void {
    this.dropDownOpen = false;
  }

  showDropdown(value: boolean): void {
    this.dropDownOpen = value;
    this.hasNewRides = value;
  }

  sendLinkPopupActivator() {
    this.dialog.open(SendLinkPopupComponent);
  }

  onRideSelected(rideId: string): void {
    this.markerId = rideId;
  }

  async update() {
    clearTimeout(this.updateTimer);

    this.getTaxiService.getCancelledRidesByDriver()
      .subscribe(async data => {
        if (data && data.length > 0) {
          this.dialog.open(CancelledByDriverComponent, { data, disableClose: true });
        }
        this.updateTimer = window.setTimeout(() => this.update(), 300000);
      });
  }

  getDisplayAverage(): boolean {
    return (this.hotelInfo!.displayOptions & DisplayOptions.DisplayAverage) === DisplayOptions.DisplayAverage
  }

  getDisplayRating(): boolean {
    return (this.hotelInfo!.displayOptions & DisplayOptions.DisplayRating) === DisplayOptions.DisplayRating
  }

  getHasRides(): string {
    return this.hasNewRides ? '▼' : ''; // Unicode down arrow
  }

  toAirport() {
    this.toAirportTab = true;
    this.otherDestinationTab = false;
    this.fromAirportTab = false;
    this.taxiSupportTab = false;
  }

  otherDestination() {
    this.toAirportTab = false;
    this.otherDestinationTab = true;
    this.fromAirportTab = false;
    this.priceService.setAirportDirection(undefined);
    this.taxiSupportTab = false;
    this.taxiConfigurationService.updatePriorityGroup(RideType.FixedPrice);
  }

  fromAirport() {
    this.toAirportTab = false;
    this.otherDestinationTab = false;
    this.fromAirportTab = true;
    this.taxiSupportTab = false;
    this.taxiConfigurationService.updatePriorityGroup(RideType.FromAirport);
  }

  taxiSupport() {
    this.toAirportTab = false;
    this.otherDestinationTab = false;
    this.fromAirportTab = false;
    this.taxiSupportTab = true;
  }
}
